import { useRef, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Accordion, AccordionSummary, AccordionDetails } from "@mui/material";
import ExpandMore from "@mui/icons-material/ExpandMore";
import { Title2, Li } from "../components/Font";
import './questions.css';


const Process = props => {
  const element = useRef();
  const orgScrollHeight = useRef(document.documentElement.scrollHeight);
  const [contentHeight, setContentHeight] = useState(0);
  const navigate = useNavigate();
  
  const handleFaq = () => {
    if ("pHistory" in props && props.pHistory.current[props.pHistory.current.length-2] == "/faq"){
      navigate(-1);
    }
    else{
      navigate("/faq");
    }
  }
  
  useEffect(() => {
    const resize = new ResizeObserver(entries => {
      for (const entry of entries){
        const {width, height} = entry.contentRect;
        setContentHeight(height);
      }
    });
    if(element.current){
      resize.observe(element.current);
    }
    
    return () => {
      resize.disconnect();
    };
  }, [element]);
  
  useEffect(() => {
    if (orgScrollHeight.current <= contentHeight + 400) {
      props.disableFillHeight();
    }
    else{
      props.enableFillHeight();
    }
  }, [contentHeight]);
  
  
  return (
    <div style={{ display: 'flex', flex: 1, justifyContent: 'center', alignItems: 'center' }} >
      <div ref={element}>
        {props.innerWidth > 768 ?
        <>
            <div style={{ height: '100px' }} />
            <div style={{ color: '#AAAAAA', fontSize: '18px', fontWeight: 600, display: 'flex', alignItems: 'center' }}>
              <span style={{ cursor: 'pointer' }} onClick={() => handleFaq()}>자주 묻는 질문</span>
              <img src="/images/faqchevron.png" style={{height: '21px', margin: '0 6px'}}/> 도입프로세스
            </div>
            <div style={{ height: '20px' }} />
            <div style={{ fontSize: '42px', fontWeight: 'bold' }}>도입프로세스</div>
            <div style={{ height: '40px' }} />

            <div style={{ maxWidth: '1300px' }}>
              <Accordion disableGutters elevation={0} square style={{ borderBottom: '1px solid #EEEEEE' }}>
                <AccordionSummary expandIcon={<ExpandMore fontSize="large" />} >
                  <div style={{ fontSize: '24px', fontWeight: 600, padding: '20px 24px' }}>
                    <span style={{ color: '#00CD9B' }}>Q.</span> E-Scope를 도입하기 위한 기간은 얼마나 소요되나요?
                  </div>
                </AccordionSummary>
                <AccordionDetails>
                  <div style={{ fontSize: '18px', color: '#757575', margin: '0 30px' }}>
                    <ul>
                      <Li>기업은 최소 2주 만에 이스코프를 도입하고 Scope 1, 2 & 3 를 포함하는 완전한 탄소 현황과 함께 지속가능한 탄소 관리를 할 수 있는 업무 파이프라인을 구축할 수 있습니다.</Li>
                      <Li>기업의 규모와 도입 범위와 요구조건에 따라 도입 기간은 달라질 수 있으며 대기업의 경우 최대 3~4달 정도, 중견/중소기업의 경우 최소 2주 ~ 최대 2달 정도를 소요하고 있습니다.</Li>
                    </ul>
                  </div>
                </AccordionDetails>
              </Accordion>

              <Accordion disableGutters elevation={0} square style={{ borderBottom: '1px solid #EEEEEE' }}>
                <AccordionSummary expandIcon={<ExpandMore fontSize="large" />}>
                  <div style={{ fontSize: '24px', fontWeight: 600, padding: '20px 24px' }}>
                    <span style={{ color: '#00CD9B' }}>Q.</span> 기존에 내부에서 이미 엑셀로 관리하고 있던 온실가스 인벤토리가 있습니다. 이러한 경우에는 어떻게 도입이 진행이 되나요?
                  </div>
                </AccordionSummary>
                <AccordionDetails>
                  <div style={{ fontSize: '18px', color: '#757575', margin: '0 30px' }}>
                    <ul>
                      <Li>기존의 수기, 엑셀 또는 시스템으로 기업에서 관리하시던 온실가스 인벤토리 자료가 있다면  데이터를 저희 시스템 기준으로 포멧팅을 하고<br/>
                      기존 데이터들을 이스코프로 이관시켜 드립니다. 가지고 계시던 자료를 그대로 수정 없이 전달해 주시기만 하면 됩니다</Li>
                    </ul>
                  </div>
                </AccordionDetails>
              </Accordion>

              <Accordion disableGutters elevation={0} square style={{ borderBottom: '1px solid #EEEEEE' }}>
                <AccordionSummary expandIcon={<ExpandMore fontSize="large" />}>
                  <div style={{ fontSize: '24px', fontWeight: 600, padding: '20px 24px' }}>
                    <span style={{ color: '#00CD9B' }}>Q.</span> 내부의 다양한 시스템에 배출량 산정에 필요한 데이터가 파편화되어 있습니다. 연동이 가능한가요?
                  </div>
                </AccordionSummary>
                <AccordionDetails>
                  <div style={{ fontSize: '18px', color: '#757575', margin: '0 30px' }}>
                    <ul>
                      <Li>이스코프는 자동화된 데이터 수집 파이프라인과 기업 상황에 맞춘 최적화된 솔루션을 통해 연동이 가능합니다. </Li>
                    </ul>
                  </div>
                </AccordionDetails>
              </Accordion>

              <Accordion disableGutters elevation={0} square style={{ borderBottom: '1px solid #EEEEEE' }}>
                <AccordionSummary expandIcon={<ExpandMore fontSize="large" />}>
                  <div style={{ fontSize: '24px', fontWeight: 600, padding: '20px 24px' }}>
                    <span style={{ color: '#00CD9B' }}>Q.</span> 이스코프는 어떤 형태로 구축이 진행이되나요?
                  </div>
                </AccordionSummary>
                <AccordionDetails>
                  <div style={{ fontSize: '18px', color: '#757575', margin: '0 30px' }}>
                    <ul>
                      <Li>이스코프는 클라우드 형태로 제공되는 B2B SaaS 프로그램입니다. 구축을 위해 따로 서버 설치 및 비용 청구 등을 필요로 하지 않으며 웹 브라우저를 통해 바로 서비스를 이용하실 수 있습니다.</Li>
                      <Li>SasS 형태로 고객의 데이터는 암호화 및 블록체인위에 올라가기 때문에 보안에 안전합니다.</Li>
                    </ul>
                  </div>
                </AccordionDetails>
              </Accordion>

              <Accordion disableGutters elevation={0} square >
                <AccordionSummary expandIcon={<ExpandMore fontSize="large" />}>
                  <div style={{ fontSize: '24px', fontWeight: 600, padding: '20px 24px' }}>
                    <span style={{ color: '#00CD9B' }}>Q.</span> 내부에서 사용하는 ESG, 환경 관련 시스템 등이 있습니다. 기존 시스템과 함께 사용하고 싶은데 어떻게 진행되나요?
                  </div>
                </AccordionSummary>
                <AccordionDetails>
                  <div style={{ fontSize: '18px', color: '#757575', margin: '0 30px' }}>
                    <ul>
                      <Li>내부에서 기존에 사용하던 ESG, 환경 관련 시스템과 함께 사용하고 싶다면 해당 시스템에서 필요한 데이터만을 저희 데이터컨버터를 통해<br />
                        이스코프로 연결해 유연하게 탄소 관리 업무 파이프라인을 구축할 수 있습니다.</Li>
                    </ul>
                  </div>
                </AccordionDetails>
              </Accordion>
            </div>
            <div style={{ height: '100px' }} />
        </>
        :
        <div style={{width: '100%', padding: '50px 0', display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
          <div style={{width: '85%'}}>
            <div style={{ color: '#AAAAAA', fontSize: '14px', fontWeight: 600, display: 'flex', alignItems: 'center' }}>
              <span style={{ cursor: 'pointer' }} onClick={() => handleFaq()}>자주 묻는 질문</span>
              <img src="/images/faqchevron.png" style={{ height: '16px', margin: '0 2px' }} /> 도입프로세스
            </div>
            <div style={{ height: '10px' }} />
            <Title2>도입프로세스</Title2>
            <div style={{ height: '30px' }} />

            <div>
              <Accordion disableGutters elevation={0} square style={{ borderBottom: '1px solid #EEEEEE' }}>
                <AccordionSummary expandIcon={<ExpandMore />} sx={{padding: '0 10px'}} >
                  <div style={{ fontSize: '14px', fontWeight: 600, padding: '8px 10px', display: 'flex' }}>
                    <div style={{ color: '#00CD9B', marginRight: '6px' }}>Q.</div>
                    <div>E-Scope를 도입하기 위한 기간은 얼마나 소요되나요?</div>
                  </div>
                </AccordionSummary>
                <AccordionDetails sx={{ padding: '0' }}>
                  <div style={{ fontSize: '18px', color: '#757575', margin: '-20px 40px 0 14px' }}>
                    <ul>
                      <Li>기업은 최소 2주 만에 이스코프를 도입하고 Scope 1, 2 & 3 를 포함하는 완전한 탄소 현황과 함께 지속가능한 탄소 관리를 할 수 있는 업무 파이프라인을 구축할 수 있습니다.</Li>
                      <div style={{height: '10px'}} />
                      <Li>기업의 규모와 도입 범위와 요구조건에 따라 도입 기간은 달라질 수 있으며 대기업의 경우 최대 3~4달 정도, 중견/중소기업의 경우 최소 2주 ~ 최대 2달 정도를 소요하고 있습니다.</Li>
                    </ul>
                  </div>
                </AccordionDetails>
              </Accordion>

              <Accordion disableGutters elevation={0} square style={{ borderBottom: '1px solid #EEEEEE' }}>
                <AccordionSummary expandIcon={<ExpandMore />} sx={{ padding: '0 10px' }} >
                  <div style={{ fontSize: '14px', fontWeight: 600, padding: '8px 10px', display: 'flex' }}>
                    <div style={{ color: '#00CD9B', marginRight: '6px' }}>Q.</div>
                    <div>기존에 내부에서 이미 엑셀로 관리하고 있던 온실가스 인벤토리가 있습니다. 이러한 경우에는 어떻게 도입이 진행이 되나요?</div>
                  </div>
                </AccordionSummary>
                <AccordionDetails sx={{ padding: '0' }}>
                  <div style={{ fontSize: '18px', color: '#757575', margin: '-20px 40px 0 14px' }}>
                    <ul>
                      <Li>기존의 수기, 엑셀 또는 시스템으로 기업에서 관리하시던 온실가스 인벤토리 자료가 있다면  데이터를 저희 시스템 기준으로 포멧팅을 하고</Li>
                      <div style={{ height: '10px' }} />
                      <Li>기존 데이터들을 이스코프로 이관시켜 드립니다. 가지고 계시던 자료를 그대로 수정 없이 전달해 주시기만 하면 됩니다</Li>
                    </ul>
                  </div>
                </AccordionDetails>
              </Accordion>

              <Accordion disableGutters elevation={0} square style={{ borderBottom: '1px solid #EEEEEE' }}>
                <AccordionSummary expandIcon={<ExpandMore />} sx={{ padding: '0 10px' }} >
                  <div style={{ fontSize: '14px', fontWeight: 600, padding: '8px 10px', display: 'flex' }}>
                    <div style={{ color: '#00CD9B', marginRight: '6px' }}>Q.</div>
                    <div>내부의 다양한 시스템에 배출량 산정에 필요한 데이터가 파편화되어 있습니다. 연동이 가능한가요?</div>
                  </div>
                </AccordionSummary>
                <AccordionDetails sx={{ padding: '0' }}>
                  <div style={{ fontSize: '18px', color: '#757575', margin: '-20px 40px 0 14px' }}>
                    <ul>
                      <Li>이스코프는 자동화된 데이터 수집 파이프라인과 기업 상황에 맞춘 최적화된 솔루션을 통해 연동이 가능합니다. </Li>
                    </ul>
                  </div>
                </AccordionDetails>
              </Accordion>

              <Accordion disableGutters elevation={0} square style={{ borderBottom: '1px solid #EEEEEE' }}>
                <AccordionSummary expandIcon={<ExpandMore />} sx={{ padding: '0 10px' }} >
                  <div style={{ fontSize: '14px', fontWeight: 600, padding: '8px 10px', display: 'flex' }}>
                    <div style={{ color: '#00CD9B', marginRight: '6px' }}>Q.</div>
                    <div>이스코프는 어떤 형태로 구축이 진행이되나요?</div>
                  </div>
                </AccordionSummary>
                <AccordionDetails sx={{ padding: '0' }}>
                  <div style={{ fontSize: '18px', color: '#757575', margin: '-20px 40px 0 14px' }}>
                    <ul>
                      <Li>이스코프는 클라우드 형태로 제공되는 B2B SaaS 프로그램입니다. 구축을 위해 따로 서버 설치 및 비용 청구 등을 필요로 하지 않으며 웹 브라우저를 통해 바로 서비스를 이용하실 수 있습니다.</Li>
                      <div style={{ height: '10px' }} />
                      <Li>SasS 형태로 고객의 데이터는 암호화 및 블록체인위에 올라가기 때문에 보안에 안전합니다.</Li>
                    </ul>
                  </div>
                </AccordionDetails>
              </Accordion>

              <Accordion disableGutters elevation={0} square>
                <AccordionSummary expandIcon={<ExpandMore />} sx={{ padding: '0 10px' }} >
                  <div style={{ fontSize: '14px', fontWeight: 600, padding: '8px 10px', display: 'flex' }}>
                    <div style={{ color: '#00CD9B', marginRight: '6px' }}>Q.</div>
                    <div>내부에서 사용하는 ESG, 환경 관련 시스템 등이 있습니다. 기존 시스템과 함께 사용하고 싶은데 어떻게 진행되나요?</div>
                  </div>
                </AccordionSummary>
                <AccordionDetails sx={{ padding: '0' }}>
                  <div style={{ fontSize: '18px', color: '#757575', margin: '-20px 40px 0 14px' }}>
                    <ul>
                      <Li>내부에서 기존에 사용하던 ESG, 환경 관련 시스템과 함께 사용하고 싶다면 해당 시스템에서 필요한 데이터만을 저희 데이터컨버터를 통해<br />
                        이스코프로 연결해 유연하게 탄소 관리 업무 파이프라인을 구축할 수 있습니다.</Li>
                    </ul>
                  </div>
                </AccordionDetails>
              </Accordion>
            </div>
          </div>
        </div>
        }
      </div>
    </div>
  )
}

export default Process;
