import { useEffect, useState } from "react";
import Flicking from "@egjs/react-flicking";
import "@egjs/react-flicking/dist/flicking.css";
import { AutoPlay } from "@egjs/flicking-plugins";
import { Slide } from "@mui/material";
import { TitleText, Title2, BodyText } from "../components/Font";


const Index = ({innerWidth}) => {
  const [slide1, setSlide1] = useState(false);
  
  useEffect(() => {
    const handleScroll = () => {
      const scrollY = window.scrollY;
      const windowHeight = window.innerHeight;

      if (innerWidth > 768){
        if (scrollY + windowHeight >= 2700) {
          setSlide1(true);
        }
      }
      else{
        if (scrollY + windowHeight >= 1700) {
          setSlide1(true);
        }
      }
    };

    // 스크롤 이벤트 리스너를 추가합니다.
    window.addEventListener('scroll', handleScroll);

    // 컴포넌트가 언마운트될 때 이벤트 리스너를 제거합니다.
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);
  
  return (
    <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%'}}>
      <div style={{ height: innerWidth > 768 ? '100px' : '60px' }} />
      <TitleText>쉽고 빠르게 시작하는</TitleText>
      {/* <div style={{height: '20px'}} /> */}
      <TitleText>탄소배출관리, <span style={{color: "#00CD9B"}}>E-scope</span></TitleText>
      <div style={{ height: innerWidth > 768 ? '50px': '20px' }} />
      <BodyText>ESG, 이제 선택이 아닌 필수입니다.</BodyText>
      {innerWidth > 768 ? 
        <BodyText>최적의 솔루션을 통하여 당신의 지속가능한 경영을 실현해보세요</BodyText> :
        <>
        <BodyText>최적의 솔루션을 통하여 당신의</BodyText>
        <BodyText>지속가능한 경영을 실현해보세요</BodyText>
        </>
      }
      <div style={{ height: innerWidth > 768 ? '60px' : '30px' }} />
      <button
        style={innerWidth > 768 ? {
          backgroundColor: "#00CD9B", width: "250px", height: "60px", fontSize: '24px', fontWeight: 'bold', color: '#FFFFFF',
          borderRadius: '10px', border: 'none'
        } : {
          backgroundColor: "#00CD9B", width: "174px", height: "51px", fontSize: '18px', fontWeight: 'bold', color: '#FFFFFF',
          borderRadius: '8px', border: 'none'
        }}
        onClick={() => window.open("https://walla.my/escope.inbound", "_blank")}
      >
        <span style={{ fontFamily: 'Pretendard'}}>
        도입 문의하기
        </span>
      </button>
      <div style={{ height: innerWidth > 768 ? '60px': '40px'}} />
      {innerWidth > 768 ?
        <div style={{ width: '100vw', overflow:'hidden'}}>
          <Flicking
            plugins={[new AutoPlay({ duration: 0, direction: "NEXT", stopOnHover: false })]}
            align="prev"
            circular={true}
            duration={15000}
            easing={x=>x}
            
          >
            <div className="panel" style={{ width: '1300px', height: '758px', marginRight: '100px', backgroundImage: 'url(/images/index11.jpg)', backgroundSize: '100% auto', backgroundRepeat: 'no-repeat', borderRadius: '15px' }} />
            <div className="panel" style={{ width: '1300px', height: '758px', marginRight: '100px', backgroundImage: 'url(/images/index13.jpg)', backgroundSize: '100% auto', backgroundRepeat: 'no-repeat', borderRadius: '15px' }} />
            <div className="panel" style={{ width: '1300px', height: '758px', marginRight: '100px', backgroundImage: 'url(/images/index12.jpg)', backgroundSize: '100% auto', backgroundRepeat: 'no-repeat', borderRadius: '15px' }} />
          </Flicking>
        </div>
        :
        <div style={{ width: '100vw', overflow: 'hidden' }}>
          <Flicking
            plugins={[new AutoPlay({ duration: 0, direction: "NEXT", stopOnHover: false })]}
            align="prev"
            circular={true}
            duration={15000}
            easing={x=>x}
          >
            <div className="panel" style={{ width: '100%', paddingBottom: '58.5%', marginRight: '20px', backgroundImage: 'url(/images/index11m.png)', backgroundSize: '100% auto', backgroundRepeat: 'no-repeat', borderRadius: '15px' }} />
            <div className="panel" style={{ width: '100%', paddingBottom: '58.5%', marginRight: '20px', backgroundImage: 'url(/images/index12m.png)', backgroundSize: '100% auto', backgroundRepeat: 'no-repeat', borderRadius: '15px' }} />
            <div className="panel" style={{ width: '100%', paddingBottom: '58.5%', marginRight: '20px', backgroundImage: 'url(/images/index13m.png)', backgroundSize: '100% auto', backgroundRepeat: 'no-repeat', borderRadius: '15px' }} />
          </Flicking>
        </div>
      }
      
      
      <div style={{ height: innerWidth > 768 ? '100px' : '40px' }} />
      
      {innerWidth > 768 ?
        <div style={{ backgroundColor: "#F5F9F8", width: "100%", padding: '100px 0', display: 'flex', justifyContent: 'center' }}>
          <div style={{ width: '100%', maxWidth: '1300px', padding: '0 100px', display: 'flex', alignItems: 'center' }}>
            <div style={{flex: 1}}>
              <Title2>지속가능성의 위험과 기회,</Title2>
              <Title2 style={{ color: "#00CD9B" }}>저탄소 전환</Title2>
              <div style={{ height: '20px' }} />
              <BodyText>기후 변화에 따른 사회적 영향력이 중요해 짐에 따라 비재무적 지표가</BodyText>
              <BodyText>실질적 가치 평가의 중요한 기준이 되고 있습니다.</BodyText>
              <BodyText>투자자/주주의 요구 증대에 따른 저탄소 전환을 E-scope와 함께하세요.</BodyText>
            </div>
            <div style={{ minWidth: '60px' }} />
            <div style={{ height: "460px", overflow: 'hidden' }}>
              <img src="/images/index3.avif" style={{ height: '100%', borderRadius: '15px' }} />
            </div>
          </div>
        </div>
        :
        <div style={{ backgroundColor: "#F5F9F8", width: "100%", padding: '50px 0', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <div style={{width: '85%'}}>
            <Title2>지속가능성의 위험과 기회,</Title2>
            <Title2 style={{ color: "#00CD9B" }}>저탄소 전환</Title2>
            <div style={{ height: '20px' }} />
            <BodyText>기후 변화에 따른 사회적 영향력이</BodyText>
            <BodyText>중요해 짐에 따라 비재무적 지표가 실질적</BodyText>
            <BodyText>가치 평가의 중요한 기준이 되고 있습니다.</BodyText>
            <div style={{ height: '20px' }} />
            <BodyText>투자자/주주의 요구 증대에 따른</BodyText>
            <BodyText>저탄소 전환을 E-scope와 함께하세요.</BodyText>
          
            <div style={{ height: '40px' }} />
            <div style={{ width: '100%', paddingBottom: '66.5%', backgroundImage: 'url(/images/index3.avif)', backgroundSize: '100% auto', backgroundRepeat: 'no-repeat', borderRadius: '15px' }} />
          </div>
        </div>
      }
      
      {innerWidth > 768 ?
        <div style={{ width: '100%', padding: '100px 0', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
            <Slide direction="up" in={slide1}>
              <div>
                <Title2 style={{ textAlign: 'center' }}>탄소 배출 관리, 여전히 어렵게 느껴지시나요?</Title2>
                <div style={{ height: '40px' }} />
                <img src="/images/index2.png" style={{ width: '896px' }} />
              </div>
            </Slide>
          </div>
        </div>
        :
        <div style={{ width: '100%', padding: '50px 0', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <div style={{ width: '85%' }}>
            <Slide direction="up" in={slide1}>
              <div>
                <Title2 style={{ textAlign: 'center' }}>탄소 배출 관리,</Title2>
                <Title2 style={{ textAlign: 'center' }}>여전히 어렵게 느껴지시나요?</Title2>
                <div style={{ height: '40px' }} />
                <img src="/images/index2m.png" style={{ width: '100%' }} />
              </div>
            </Slide>
          </div>
        </div>
      }
      
      
      {innerWidth > 768 ?
        <div style={{ backgroundColor: "#F5F9F8", width: "100%", height: "660px", }}>
          <div style={{ height: '100px' }} />
          <div style={{ textAlign: 'center', fontSize: '42px', fontWeight: 'bold' }}>
            E-scope의 ONE-STOP 탄소배출관리 솔루션
          </div>
          <div style={{ height: '30px' }} />
          <div style={{ textAlign: 'center', margin: '3px 0', fontSize: '18px', color: '#757575' }}>
            1:1 컨설팅을 통해 Scope 3,4를 아우를 수 있는 기업 맞춤형 솔루션을 제공합니다.
          </div>
          <div style={{ textAlign: 'center', margin: '3px 0', fontSize: '18px', color: '#757575' }}>
            이를 통해 온실가스 배출량 산정부터 감축, 목표 설정까지 효율적으로 달성하세요.
          </div>
          <div style={{ height: '40px' }} />
          <div style={{ height: "220px", display: 'flex', justifyContent: 'center' }}>
            <img src="/images/index5.png" style={{ height: '100%' }} />
          </div>
        </div>
        :
        <div style={{ backgroundColor: "#F5F9F8", width: "100%", padding: '50px 0', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <div style={{ width: '85%' }}>
            <Title2 style={{ textAlign: 'center' }}>E-scope의 ONE-STOP</Title2>
            <Title2 style={{ textAlign: 'center' }}>탄소배출관리 솔루션</Title2>
            <div style={{ height: '20px' }} />
            <BodyText style={{ textAlign: 'center' }}>1:1 컨설팅을 통해 Scope 3,4를 아우를 수</BodyText>
            <BodyText style={{ textAlign: 'center' }}>있는 기업 맞춤형 솔루션을 제공합니다.</BodyText>
            <div style={{ height: '20px' }} />
            <BodyText style={{ textAlign: 'center' }}>투자자/주주의 요구 증대에 따른</BodyText>
            <BodyText style={{ textAlign: 'center' }}>저탄소 전환을 E-scope와 함께하세요.</BodyText>
            <div style={{ height: '20px' }} />
            <img src="/images/index5m.png" style={{ width: '100%' }} />
          </div>
        </div>
      }
      
      
      {innerWidth > 768 ?
        <div style={{ padding: '100px 0', display: 'flex', flexDirection: 'column', alignContent: 'center', justifyContent: 'center' }}>
          {/* <div style={{ height: '100px' }} /> */}
          <div style={{ textAlign: 'center', color: '#111111', fontSize: '42px', fontWeight: 'bold', margin: '5px 0' }}>
            각 기업의 사업 영역과 현상에 맞춘
          </div>
          <div style={{ textAlign: 'center', color: '#111111', fontSize: '42px', fontWeight: 'bold' }}>
            효율적인 탄소 감축 목표 설정
          </div>
          <div style={{ height: '20px' }} />
          <div style={{ textAlign: 'center', color: '#757575', fontSize: '18px' }}>
            이를 통해 불필요한 탄소 배출원을 파악하고 지속 가능한 탄소 저감 활동을 지속해보세요.
          </div>
          <div style={{ height: '60px' }} />
          <div style={{ display: 'flex', width: '100%' }}>
            <div>
              <div style={{ width: '630px', height: '380px', backgroundImage: 'url(/images/index6.avif)', backgroundSize: 'contain', display: 'flex', flexDirection: 'row', alignContent: 'center', borderRadius: '15px' }}>
                <div style={{ width: '60px' }} />
                <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                  <div style={{ fontSize: '30px', fontWeight: 'bold', color: '#FFFFFF' }}>데이터 수기 입력의</div>
                  <div style={{ height: '10px' }} />
                  <div style={{ fontSize: '30px', fontWeight: 'bold', color: '#FFFFFF' }}>번거로움 탈피</div>
                  <div style={{ height: '30px' }} />
                  <div style={{ color: '#FFFFFF' }}>엑셀 등 수기 입력의 불필요한 시간과</div>
                  <div style={{ height: '5px' }} />
                  <div style={{ color: '#FFFFFF' }}>노동 시간 감축, 클라우드 기반의 효율적인</div>
                  <div style={{ height: '5px' }} />
                  <div style={{ color: '#FFFFFF' }}>데이터 관리 및 자동 리포팅 제공</div>
                </div>
              </div>
              <div style={{ height: '40px' }} />
              <div style={{ width: '630px', height: '380px', backgroundImage: 'url(/images/index7.jpg)', backgroundSize: 'contain', display: 'flex', flexDirection: 'row', alignContent: 'center', borderRadius: '15px' }}>
                <div style={{ width: '60px' }} />
                <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                  <div style={{ fontSize: '30px', fontWeight: 'bold', color: '#FFFFFF' }}>3-Scope 대응 가능한</div>
                  <div style={{ height: '10px' }} />
                  <div style={{ fontSize: '30px', fontWeight: 'bold', color: '#FFFFFF' }}>기업 맞춤형 솔루션</div>
                  <div style={{ height: '30px' }} />
                  <div style={{ color: '#FFFFFF' }}>1:1 기업 컨설팅을 통한 맞춤형 산정식 제공,</div>
                  <div style={{ height: '5px' }} />
                  <div style={{ color: '#FFFFFF' }}>Scope 3의 애매모호함 제거,</div>
                  <div style={{ height: '5px' }} />
                  <div style={{ color: '#FFFFFF' }}>나아가 4까지 대응 가능</div>
                </div>
              </div>
            </div>
            <div style={{ width: '40px' }} />
            <div style={{ width: '630px', height: '800px', background: 'linear-gradient( rgba(0,0,0,0.6),rgba(0,0,0,0.6) ), url(/images/index8.avif)', backgroundSize: '100% auto', backgroundPosition: '0px -100px', borderRadius: '15px' }}>
              <div style={{ height: '80px' }} />
              <div style={{ paddingLeft: '60px' }}>
                <div style={{ fontSize: '30px', fontWeight: 'bold', color: '#FFFFFF' }}>정량, 정성적 평가 방법</div>
                <div style={{ height: '10px' }} />
                <div style={{ fontSize: '30px', fontWeight: 'bold', color: '#FFFFFF' }}>모두 활용</div>
                <div style={{ height: '30px' }} />
                <div style={{ color: '#FFFFFF' }}>EEIO, LCA 방법론 모두 활용한 서비스 제공,</div>
                <div style={{ height: '5px' }} />
                <div style={{ color: '#FFFFFF' }}>효과적인 탄소 배출량 계산 가능</div>
              </div>
            </div>
          </div>
          {/* <div style={{ height: '100px' }} /> */}
        </div>
        :
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', padding: '50px 0' }}>
          <div style={{width: '85%'}}>
            <Title2 style={{ textAlign: 'center' }}>각 기업의 사업 영역과 현상에 맞춘</Title2>
            <Title2 style={{ textAlign: 'center' }}>효율적인 탄소 감축 목표 설정</Title2>
            <div style={{ height: '20px' }} />
            <BodyText style={{ textAlign: 'center' }}>이를 통해 불필요한 탄소 배출원을 파악하고</BodyText>
            <BodyText style={{ textAlign: 'center' }}>지속 가능한 탄소 저감 활동을 지속해보세요.</BodyText>
            <div style={{ height: '40px' }} />
            <img src="/images/index6m.png" style={{ width: '100%', borderRadius: '15px' }} />
            <div style={{ height: '20px' }} />
            <img src="/images/index7m.png" style={{ width: '100%', borderRadius: '15px' }} />
            <div style={{ height: '20px' }} />
            <img src="/images/index8m.png" style={{ width: '100%', borderRadius: '15px' }} />
          </div>
        </div>
      }
      
      
      
      {innerWidth > 768 ?
        <div style={{ width: '100%', padding: '100px 0', backgroundColor: '#F5F9F8', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', padding: '0 100px', maxWidth: '1300px' }}>
            {/* <div style={{ width: '310px' }} /> */}
            <div style={{ display: 'flex', flex: 1, flexDirection: 'column' }}>
              <div style={{ fontSize: '42px', fontWeight: 'bold' }}>Contact Us</div>
              <div style={{ height: '30px' }} />
              <div style={{ fontSize: '18px', color: '#757575' }}>궁금한 점 남겨주세요. 최대한 빠르게 답변 도와드리겠습니다.</div>
            </div>
            <button
              style={{
                backgroundColor: "#00CD9B", width: "250px", height: "60px", fontSize: '24px', fontWeight: 'bold', color: '#FFFFFF',
                borderRadius: '10px', border: 'none'
              }}
              onClick={() => window.open("https://walla.my/escope.inbound", "_blank")}
            >
              <span style={{ fontFamily: 'Pretendard' }}>
              도입 문의하기
              </span>
            </button>
            {/* <div style={{ width: '310px' }} /> */}
          </div>
        </div>
        :
        <div style={{ width: '100%', padding: '50px 0', backgroundColor: '#F5F9F8', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <div style={{width: '85%'}}>
            <Title2>Contact Us</Title2>
            <div style={{ height: '10px' }} />
            <BodyText>궁금한 점 남겨주세요.</BodyText>
            <BodyText>최대한 빠르게 답변 도와드리겠습니다.</BodyText>
            <div style={{ height: '30px' }} />
            <button
              style={{
                backgroundColor: "#00CD9B", width: "174px", height: "51px", fontSize: '18px', fontWeight: 'bold', color: '#FFFFFF',
                borderRadius: '8px', border: 'none'
              }}
              onClick={() => window.open("https://walla.my/escope.inbound", "_blank")}
            >
              도입 문의하기
            </button>
          </div>
        </div>
      }
      
      
    </div>
  )
}

export default Index;
