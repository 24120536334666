import { styled } from "@mui/material";


export const Li = styled('li')(() => ({
  lineHeight: 1.5,
  '@media (max-width: 768px)': {
    fontSize: '14px',
  },
}));

export const TitleText = styled('div')(() => ({
  fontSize: '60px',
  fontWeight: 'bold',
  lineHeight: 1.5,
  '@media (max-width: 768px)': {
    fontSize: '24px',
    lineHeight: 1.6
  },
}));

export const Title2 = styled('div')(() => ({
  fontSize: '42px',
  fontWeight: 'bold',
  lineHeight: 1.5,
  '@media (max-width: 768px)': {
    fontSize: '24px',
    lineHeight: 1.6
  },
}));

export const BodyText = styled('div')(() => ({
  color: '#757575',
  fontSize: '18px',
  lineHeight: 1.5,
  // '@media (max-width: 768px)': {
  //   fontSize: '24px',
  //   lineHeight: 1.6
  // },
}));
