import { useRef, useState, useEffect } from "react";
import { Slide } from "@mui/material";
import { Title2, BodyText } from "../components/Font";


const Management = ({innerWidth}) => {
  const slideRef = useRef();
  const [slide, setSlide] = useState(false);

  useEffect(() => {
    setSlide(true);
  }, []);
  
  return (
    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%' }}>
      
      {innerWidth > 768 ?
        <div ref={slideRef} style={{ width: '100%', padding: '100px 0', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <Slide direction="up" in={slide} container={slideRef.current} timeout={800}>
            <div>
              <div style={{ fontSize: '42px', fontWeight: 'bold' }}>탄소중립이란?</div>
              <div style={{ height: '20px' }} />
              <BodyText>대기 중에 배출, 방출 또는 누출되는 온실가스의 양에서 온실가스 흡수의 양을 상쇄하여 순배출량이 “0(zero)”이 되는 상태를 의미합니다.</BodyText>
              <BodyText>즉, 온실가스 배출을 최소화하고 남은 온실가스는 흡수(산림), 제거를 통해 실질적인 배출량이 0이 되는 개념이기에, Net-Zero라는 또 다른 용어로 불립니다.</BodyText>

              <div style={{ height: '60px' }} />

              <div style={{
                width: '1300px', height: '500px', borderRadius: '15px', background: 'linear-gradient( rgba(0,0,0,0.5),rgba(0,0,0,0.5) ), url(/images/management1.avif)',
                backgroundSize: '100% auto', backgroundPosition: '0px 75%', display: 'flex', alignItems: 'center', justifyContent: 'center'
              }}>
                <div style={{ color: '#FFFFFF', fontSize: '100px', fontWeight: 'bold' }}>Net-Zero</div>
              </div>
            </div>
          </Slide>
        </div>
        :
        <div ref={slideRef} style={{ width: '100%', padding: '50px 0', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <div style={{width: '85%'}}>
            <Slide direction="up" in={slide} container={slideRef.current} timeout={800}>
              <div>
                <Title2>탄소중립이란?</Title2>
                <div style={{ height: '20px' }} />
                <BodyText>대기 중에 배출, 방출 또는 누출되는</BodyText>
                <BodyText>온실가스의 양에서 온실가스 흡수의 양을</BodyText>
                <BodyText>상쇄하여 순배출량이 “0(zero)”이 되는</BodyText>
                <BodyText>상태를 의미합니다.</BodyText>
                <div style={{ height: '20px' }} />
                <BodyText>즉, 온실가스 배출을 최소화하고</BodyText>
                <BodyText>남은 온실가스는 흡수(산림), 제거를 통해</BodyText>
                <BodyText>실질적인 배출량이 0이 되는 개념이기에,</BodyText>
                <BodyText>Net-Zero라는 또 다른 용어로 불립니다.</BodyText>

                <div style={{ height: '30px' }} />
                
                <img src="/images/management1m.png" style={{ width: '100%', borderRadius: '15px' }} />
              </div>
            </Slide>
          </div>
        </div>
      }
      
      
      {innerWidth > 768 ?
        <div style={{ width: '100%', padding: '100px 0', display: 'flex', justifyContent: 'center', alignItems: 'center', backgroundColor: '#F5F9F8', zIndex: 1 }}>
          <div>
            <Title2 style={{ textAlign: 'center' }}>기업의 탄소중립 전환 필요성</Title2>
            <div style={{ height: '20px' }} />
            <BodyText style={{ textAlign: 'center' }}>탄소 배출 감축을 위한 국제사회의 노력이 활발해짐에 따라, 정부, 금융권, 민간기업 차원에서 탄소중립 선언 및 이행이 구체화되고 있습니다.<br/>
            이에 따라 주요 글로벌 기업이 공급방 기업들의 탄소중립을 요구함에 따라 기업 역시 탄소중립의 대응 전략을 마련해야 합니다.</BodyText>
            <div style={{ height: '40px' }} />
            <div style={{ display: 'flex' }}>
              <div>
                <div style={{
                  backgroundColor: '#00CD9B', color: '#FFFFFF', fontSize: '24px', fontWeight: 600, borderRadius: '10px 10px 0 0',
                  width: '406px', height: '76px', display: 'flex', justifyContent: 'center', alignItems: 'center'
                }}>
                  기후변화에 대한 정보공개 요구
                </div>
                <div style={{
                  border: '1px solid #EEEEEE', borderRadius: '0 0 10px 10px', width: '406px', height: '121px', backgroundColor: '#FFFFFF',
                  display: 'flex', justifyContent: 'center', alignItems: 'center', fontSize: '18px', flexDirection: 'column'
                }}>
                  <div style={{ fontSize: '18px', color: '#333333', marginBottom: '5px' }}>다수의 금융 및 투자기관에서</div>
                  <div style={{ fontSize: '18px', color: '#333333', marginBottom: '5px' }}>투자대상 기업의 기후변화 정보 공개 요구</div>
                </div>
              </div>
              <div style={{ width: '40px' }} />
              <div>
                <div>
                  <div style={{
                    backgroundColor: '#00CD9B', color: '#FFFFFF', fontSize: '24px', fontWeight: 600, borderRadius: '10px 10px 0 0',
                    width: '406px', height: '76px', display: 'flex', justifyContent: 'center', alignItems: 'center'
                  }}>
                    기업에 대한 탄소중립 참여 요구
                  </div>
                  <div style={{
                    border: '1px solid #EEEEEE', borderRadius: '0 0 10px 10px', width: '406px', height: '121px', backgroundColor: '#FFFFFF',
                    display: 'flex', justifyContent: 'center', alignItems: 'center', fontSize: '18px', flexDirection: 'column'
                  }}>
                    <div style={{ fontSize: '18px', color: '#333333', marginBottom: '5px' }}>공급망 탄소중립 전환 요구 증가로,</div>
                    <div style={{ fontSize: '18px', color: '#333333', marginBottom: '5px' }}>기업 자원을 통한 가치사슬 전반의</div>
                    <div style={{ fontSize: '18px', color: '#333333', marginBottom: '5px' }}>탄소중립 전환 필요</div>
                  </div>
                </div>
              </div>
              <div style={{ width: '40px' }} />
              <div>
                <div>
                  <div style={{
                    backgroundColor: '#00CD9B', color: '#FFFFFF', fontSize: '24px', fontWeight: 600, borderRadius: '10px 10px 0 0',
                    width: '406px', height: '76px', display: 'flex', justifyContent: 'center', alignItems: 'center'
                  }}>
                    글로벌 선도 기업의 탄소 중립 선언
                  </div>
                  <div style={{
                    border: '1px solid #EEEEEE', borderRadius: '0 0 10px 10px', width: '406px', height: '121px', backgroundColor: '#FFFFFF',
                    display: 'flex', justifyContent: 'center', alignItems: 'center', fontSize: '18px', flexDirection: 'column'
                  }}>
                    <div style={{ fontSize: '18px', color: '#333333', marginBottom: '5px' }}>고객의 환경 요구 기준 강화에 따라</div>
                    <div style={{ fontSize: '18px', color: '#333333', marginBottom: '5px' }}>글로벌 선도 기업의 탄소 감축</div>
                    <div style={{ fontSize: '18px', color: '#333333', marginBottom: '5px' }}>목표 수립 및 탄소중립 선언</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        :
        <div style={{ width: '100%', padding: '50px 0', display: 'flex', flexDirection: 'column', alignItems: 'center', backgroundColor: '#F5F9F8', zIndex: 1 }}>
          <div style={{width: '85%'}}>
            <Title2>기업의 탄소중립 전환 필요성</Title2>
            <div style={{ height: '20px' }} />
            <BodyText>탄소 배출 감축을 위한 국제사회의 노력이</BodyText>
            <BodyText>활발해짐에 따라, 정부, 금융권,</BodyText>
            <BodyText>민간기업 차원에서 탄소중립 선언 및 이행이</BodyText>
            <BodyText>구체화되고 있습니다.</BodyText>
            <div style={{ height: '20px' }} />
            <BodyText>이에 따라 주요 글로벌 기업이</BodyText>
            <BodyText>공급방 기업들의 탄소중립을 요구함에 따라</BodyText>
            <BodyText>기업 역시 탄소중립의 대응 전략을</BodyText>
            <BodyText>마련해야 합니다.</BodyText>
            <div style={{ height: '40px' }} />
            <div>
              <div style={{
                backgroundColor: '#00CD9B', color: '#FFFFFF', fontSize: '18px', fontWeight: 600, borderRadius: '10px 10px 0 0',
                width: '100%', maxWidth: '425px', height: '53px', display: 'flex', justifyContent: 'center', alignItems: 'center'
              }}>
                기후변화에 대한 정보공개 요구
              </div>
              <div style={{
                border: '1px solid #EEEEEE', borderRadius: '0 0 10px 10px', width: '100%', maxWidth: '425px', height: '70px',
                display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', fontSize: '14px', backgroundColor: '#FFFFFF'
              }}>
                <div style={{ fontSize: '14px', color: '#333333', lineHeight: 1.5 }}>다수의 금융 및 투자기관에서</div>
                <div style={{ fontSize: '14px', color: '#333333', lineHeight: 1.5 }}>투자대상 기업의 기후변화 정보 공개 요구</div>
              </div>
            </div>
            <div style={{ height: '20px' }} />
            <div>
              <div>
                <div style={{
                  backgroundColor: '#00CD9B', color: '#FFFFFF', fontSize: '18px', fontWeight: 600, borderRadius: '10px 10px 0 0',
                  width: '100%', maxWidth: '425px', height: '53px', display: 'flex', justifyContent: 'center', alignItems: 'center'
                }}>
                  기업에 대한 탄소중립 참여 요구
                </div>
                <div style={{
                  border: '1px solid #EEEEEE', borderRadius: '0 0 10px 10px', width: '100%', maxWidth: '425px', height: '91px',
                  display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', fontSize: '14px', backgroundColor: '#FFFFFF'
                }}>
                  <div style={{ fontSize: '14px', color: '#333333', lineHeight: 1.5 }}>공급망 탄소중립 전환 요구 증가로,</div>
                  <div style={{ fontSize: '14px', color: '#333333', lineHeight: 1.5 }}>기업 자원을 통한 가치사슬 전반의</div>
                  <div style={{ fontSize: '14px', color: '#333333', lineHeight: 1.5 }}>탄소중립 전환 필요</div>
                </div>
              </div>
            </div>
            <div style={{ height: '20px' }} />
            <div>
              <div>
                <div style={{
                  backgroundColor: '#00CD9B', color: '#FFFFFF', fontSize: '18px', fontWeight: 600, borderRadius: '10px 10px 0 0',
                  width: '100%', maxWidth: '425px', height: '53px', display: 'flex', justifyContent: 'center', alignItems: 'center'
                }}>
                  글로벌 선도 기업의 탄소 중립 선언
                </div>
                <div style={{
                  border: '1px solid #EEEEEE', borderRadius: '0 0 10px 10px', width: '100%', maxWidth: '425px', height: '91px',
                  display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', fontSize: '14px', backgroundColor: '#FFFFFF'
                }}>
                  <div style={{ fontSize: '14px', color: '#333333', lineHeight: 1.5 }}>고객의 환경 요구 기준 강화에 따라</div>
                  <div style={{ fontSize: '14px', color: '#333333', lineHeight: 1.5 }}>글로벌 선도 기업의 탄소 감축</div>
                  <div style={{ fontSize: '14px', color: '#333333', lineHeight: 1.5 }}>목표 수립 및 탄소중립 선언</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      }
      
      
      {innerWidth > 768 ?
        <div style={{ width: '100%', padding: '100px 0', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <div style={{ color: '#00CD9B', fontSize: '24px', fontWeight: 700, textAlign: 'center' }}>
              E-Scope+로 쉽고 빠르게 배출량을 입력/관리하세요!
            </div>
            <div style={{ height: '15px' }} />
            <Title2 style={{ textAlign: 'center' }}>
              E-Scope+로 시작하는 탄소배출관리
            </Title2>
            <div style={{ height: '35px' }} />
            <BodyText style={{ textAlign: 'center' }}>
              기업 활동으로 인해 배출되는 모든 온실가스를 관리할 수 있는 최적의 온실가스 인벤토리를 구축해보세요.
            </BodyText>

            <div style={{ height: '60px' }} />

            <img src="/images/management2.png" style={{ width: '100%', maxWidth: '1300px' }} />

            <div style={{ height: '60px' }} />

            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <div style={{
                backgroundColor: '#EEEEEE', borderRadius: '15px 0 0 15px', minWidth: '244px', display: 'flex', justifyContent: 'center', alignItems: 'center',
                fontSize: '18px', fontWeight: 700
              }}>
                Scope1(직접배출)
              </div>
              <div style={{
                border: '1px solid #EEEEEE', borderRadius: '0 15px 15px 0', minWidth: '902px', padding: '16px 30px', display: 'flex', alignItems: 'center',
                fontSize: '18px', color: '#333333'
              }}>
                자동차 사용이나 에어컨 냉매 누출 등과 같이 기업에서 보유하거나 혹은 관리하는 장치의 사용에서 직접 발생한 온실가스 배출
              </div>
            </div>

            <div style={{ height: '20px' }} />

            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <div style={{
                backgroundColor: '#EEEEEE', borderRadius: '15px 0 0 15px', minWidth: '244px', display: 'flex', justifyContent: 'center', alignItems: 'center',
                fontSize: '18px', fontWeight: 700
              }}>
                Scope2(간접배출)
              </div>
              <div style={{
                border: '1px solid #EEEEEE', borderRadius: '0 15px 15px 0', minWidth: '902px', padding: '16px 30px', display: 'flex', alignItems: 'center',
                fontSize: '18px', color: '#333333'
              }}>
                전기나 난방 등 기업활동에서 필요한 에너지를 생성하기 위해 간접적으로 발생한 온실가스 배출
              </div>
            </div>

            <div style={{ height: '20px' }} />

            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <div style={{
                backgroundColor: '#EEEEEE', borderRadius: '15px 0 0 15px', minWidth: '244px', display: 'flex', justifyContent: 'center', alignItems: 'center',
                fontSize: '18px', fontWeight: 700
              }}>
                Scope3(기타간접)
              </div>
              <div style={{
                border: '1px solid #EEEEEE', borderRadius: '0 15px 15px 0', minWidth: '902px', padding: '16px 30px', display: 'flex', alignItems: 'center',
                fontSize: '18px', color: '#333333'
              }}>
                간접적으로 발생한 온실가스 중 Scope2에 포함하지 않는 모든 온실가스 배출(종업원 출퇴근, 출장 등)
              </div>
            </div>
          </div>
        </div>
        :
        <div style={{ width: '100%', padding: '50px 0', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <div style={{ width: '85%' }}>
            <div style={{ color: '#00CD9B', fontSize: '14px', fontWeight: 600, textAlign: 'center' }}>
              E-Scope+로 쉽고 빠르게 배출량을 입력/관리하세요!
            </div>
            <div style={{ height: '10px' }} />
            <Title2 style={{ textAlign: 'center' }}>
              E-Scope+로 시작하는<br/>
              탄소배출관리
            </Title2>
            <div style={{ height: '20px' }} />
            <BodyText style={{ textAlign: 'center' }}>
              기업 활동으로 인해 배출되는 모든<br/>온실가스를 관리할 수 있는 최적의 온실가스<br/>인벤토리를 구축해보세요.
            </BodyText>

            <div style={{ height: '60px' }} />

            <img src="/images/management2m.png" style={{ width: '100%', maxWidth: '425px' }} />

            <div style={{ height: '40px' }} />

            <div>
              <div style={{
                backgroundColor: '#EEEEEE', borderRadius: '10px 10px 0 0', display: 'flex', justifyContent: 'center', alignItems: 'center',
                fontSize: '14px', fontWeight: 600, width: '100%', maxWidth: '425px', height: '47px'
              }}>
                Scope1(직접배출)
              </div>
              <div style={{
                border: '1px solid #EEEEEE', borderRadius: '0 0 10px 10px', width: '100%', maxWidth: '425px', height: '91px',
                display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', fontSize: '14px', color: '#333333', lineHeight: 1.5
              }}>
                자동차 사용이나 에어컨 냉매 누출 등과 같이<br/>기업에서 보유하거나 혹은 관리하는 장치의<br/>사용에서 직접 발생한 온실가스 배출
              </div>
            </div>

            <div style={{ height: '20px' }} />

            <div>
              <div style={{
                backgroundColor: '#EEEEEE', borderRadius: '10px 10px 0 0', display: 'flex', justifyContent: 'center', alignItems: 'center',
                fontSize: '14px', fontWeight: 600, width: '100%', maxWidth: '425px', height: '47px'
              }}>
                Scope2(간접배출)
              </div>
              <div style={{
                border: '1px solid #EEEEEE', borderRadius: '0 0 10px 10px', width: '100%', maxWidth: '425px', height: '70px',
                display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', fontSize: '14px', color: '#333333', lineHeight: 1.5
              }}>
                전기나 난방 등 기업활동에서 필요한 에너지를<br/>생성하기 위해 간접적으로 발생한 온실가스 배출
              </div>
            </div>

            <div style={{ height: '20px' }} />

            <div>
              <div style={{
                backgroundColor: '#EEEEEE', borderRadius: '10px 10px 0 0', display: 'flex', justifyContent: 'center', alignItems: 'center',
                fontSize: '14px', fontWeight: 600, width: '100%', maxWidth: '425px', height: '47px'
              }}>
                Scope3(기타간접)
              </div>
              <div style={{
                border: '1px solid #EEEEEE', borderRadius: '0 0 10px 10px', width: '100%', maxWidth: '425px', height: '91px',
                display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', fontSize: '14px', color: '#333333', lineHeight: 1.5
              }}>
                간접적으로 발생한 온실가스 중 Scope2에<br/>포함하지 않는 모든 온실가스 배출<br/>(종업원 출퇴근, 출장 등)
              </div>
            </div>
          </div>
        </div>
      }
      
      
      {innerWidth > 768 ?
        <div style={{ width: '100%', padding: '100px 0', display: 'flex', justifyContent: 'center', alignItems: 'center', backgroundColor: '#F5F9F8' }}>
          <div style={{ display: 'flex', maxWidth: '1300px', width: '100%', padding: '0 100px' }}>
            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
              <Title2>E-Scope+로 더 효율적으로</Title2>
              <Title2 style={{ color: '#00CD9B' }}>탄소 중립을 달성하세요!</Title2>
              <div style={{ height: '20px' }} />
              <BodyText>E-Scope+로 각 기업의 사업 영역과 현상에 맞춰 가장 효율적으로</BodyText>
              <BodyText>탄소 감축 목표를 설정할 수 있습니다. 설정한 목표를 기초로 불필요한 탄소배출원을 파악하고</BodyText>
              <BodyText>지속가능한 방식으로 탄소 저감 활동을 계속 할 수 있습니다.</BodyText>
            </div>

            <div style={{ display: 'flex', flex: 1, maxWidth: '60px' }} />

            <div style={{
              width: '612px', height: '460px', //paddingRight: '50%',
              backgroundImage: 'url(/images/management3.avif)', backgroundSize: 'auto 100%', backgroundRepeat: 'no-repeat', borderRadius: '15px'
            }} />
          </div>
        </div>
        :
        <div style={{ width: '100%', padding: '50px 0', display: 'flex', flexDirection: 'column', alignItems: 'center', backgroundColor: '#F5F9F8' }}>
          <div style={{ width: '85%' }}>
            <div>
              <Title2>E-Scope+로 더 효율적으로</Title2>
              <Title2 style={{ color: '#00CD9B' }}>탄소 중립을 달성하세요!</Title2>
              <div style={{ height: '20px' }} />
              <BodyText>E-Scope+로 각 기업의 사업 영역과 현상에</BodyText>
              <BodyText>맞춰 가장 효율적으로 탄소 감축 목표를</BodyText>
              <BodyText>설정할 수 있습니다.</BodyText>
              <div style={{ height: '20px' }} />
              <BodyText>설정한 목표를 기초로 불필요한</BodyText>
              <BodyText>탄소배출원을 파악하고 지속가능한 방식으로</BodyText>
              <BodyText>탄소 저감 활동을 계속 할 수 있습니다.</BodyText>
            </div>

            <div style={{ height: '40px' }} />

            <img src="/images/management3.avif" style={{ width: '100%', maxWidth: '425px', borderRadius: '15px' }} />
          </div>
        </div>
      }
      
    </div>
  )
};

export default Management;
