import { useRef, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Accordion, AccordionSummary, AccordionDetails } from "@mui/material";
import ExpandMore from "@mui/icons-material/ExpandMore";
import { Title2, Li } from "../components/Font";
import './questions.css';

const Emission = props => {
  const element = useRef();
  const orgScrollHeight = useRef(document.documentElement.scrollHeight);
  const [contentHeight, setContentHeight] = useState(0);
  const navigate = useNavigate();

  const handleFaq = () => {
    if ("pHistory" in props && props.pHistory.current[props.pHistory.current.length - 2] == "/faq") {
      navigate(-1);
    }
    else {
      navigate("/faq");
    }
  }

  useEffect(() => {
    const resize = new ResizeObserver(entries => {
      for (const entry of entries) {
        const { width, height } = entry.contentRect;
        setContentHeight(height);
      }
    });
    if (element.current) {
      resize.observe(element.current);
    }

    return () => {
      resize.disconnect();
    };
  }, [element]);

  useEffect(() => {
    if (orgScrollHeight.current <= contentHeight + 400) {
      props.disableFillHeight();
    }
    else {
      props.enableFillHeight();
    }
  }, [contentHeight]);

  return (
    <div style={{ display: 'flex', flex: 1, justifyContent: 'center', alignItems: 'center' }} >
      <div ref={element}>
        {props.innerWidth > 768 ?
          <>
            <div style={{ height: '100px' }} />
            <div style={{ color: '#AAAAAA', fontSize: '18px', fontWeight: 600, display: 'flex', alignItems: 'center' }}>
              <span style={{ cursor: 'pointer' }} onClick={() => handleFaq()}>자주 묻는 질문</span>
              <img src="/images/faqchevron.png" style={{ height: '21px', margin: '0 6px' }} /> 배출량산정
            </div>
            <div style={{ height: '20px' }} />
            <div style={{ fontSize: '42px', fontWeight: 'bold' }}>배출량산정</div>
            <div style={{ height: '40px' }} />

            <div style={{ maxWidth: '1300px' }}>
              <Accordion disableGutters elevation={0} square style={{ borderBottom: '1px solid #EEEEEE' }}>
                <AccordionSummary expandIcon={<ExpandMore fontSize="large" />} >
                  <div style={{ fontSize: '24px', fontWeight: 600, padding: '20px 24px' }}>
                    <span style={{ color: '#00CD9B' }}>Q.</span> 온실가스 인벤토리란 무엇인가요?
                  </div>
                </AccordionSummary>
                <AccordionDetails>
                  <div style={{ fontSize: '18px', color: '#757575', margin: '0 30px' }}>
                    <ul>
                      <Li>온실가스 인벤토리(GHG Inventory)란 특정 기간동안 조직의 온실가스 배출원/흡수원 별 배출량/흡수량을 목록화한 것을 의미합니다. 온실가스 인벤토리 구축을 위해서는</Li>
                    </ul>
                    <div style={{ backgroundColor: '#F9F9F9', fontSize: '18px', color: '#757575', borderRadius: '15px', padding: '26px' }}>
                      <ol type="a">
                        <Li>조직 경계 설정 - 온실가스 배출량을 산정하고 인벤토리를 작성할 경계를 식별</Li>
                        <Li>배출원 확인 및 분류 - 조직 경계 내 배출활동과 배출활동별 배출시설 확인 및 분류</Li>
                        <Li>활동자료 수집 - 배출시설별 활동자료 수집</Li>
                        <Li>배출량 산정 - 배출활동별 산정방법에 따라 최종 배출량 산정</Li>
                      </ol>
                    </div>
                    <div style={{ fontSize: '18px', color: '#757575', marginTop: '10px', marginBottom: '18px' }}>위와 같은 과정을 수행해야 하며, 기업이 탄소중립 관리를 이제 처음 시작한다면 가장 먼저 해야할 일이 ‘온실가스 인벤토리 구축' 이기도 합니다.</div>
                  </div>
                </AccordionDetails>
              </Accordion>

              <Accordion disableGutters elevation={0} square style={{ borderBottom: '1px solid #EEEEEE' }}>
                <AccordionSummary expandIcon={<ExpandMore fontSize="large" />} >
                  <div style={{ fontSize: '24px', fontWeight: 600, padding: '20px 24px' }}>
                    <span style={{ color: '#00CD9B' }}>Q.</span> 탄소회계란 무엇인가요?
                  </div>
                </AccordionSummary>
                <AccordionDetails>
                  <div style={{ fontSize: '18px', color: '#757575', margin: '0 30px' }}>
                    <ul>
                      <Li>탄소회계란 사업활동으로 인해 발생한 기업의 모든 직간접적인 탄소 배출량과 감축량을 기록해 데이터로 변환하는 일련의 프로세스를 칭합니다. 기업이 탄소 정보 공개를 수행하고 목표를 설정하고 탄소중립을 이행해나가기 위해서는 필수적인 과정입니다.</Li>
                    </ul>
                  </div>
                </AccordionDetails>
              </Accordion>

              <Accordion disableGutters elevation={0} square style={{ borderBottom: '1px solid #EEEEEE' }}>
                <AccordionSummary expandIcon={<ExpandMore fontSize="large" />} >
                  <div style={{ fontSize: '24px', fontWeight: 600, padding: '20px 24px' }}>
                    <span style={{ color: '#00CD9B' }}>Q.</span> Scope 1, 2, 3 는 어떤 개념인가요?
                  </div>
                </AccordionSummary>
                <AccordionDetails>
                  <div style={{ fontSize: '18px', color: '#757575', margin: '0 30px' }}>
                    <ul>
                      <Li>Scope 1, 2, 3 는 기업이 자체적으로 소유하고 운영하는 시설에서 발생하는 직간접 배출량과 기업의 업스트림, 다운스트림을 포괄하는 가치 사슬에서 발생하는 다양한 탄소배출원을 분류하는 방법입니다. 2001년 GHG protocol에서 처음 사용되었으며 현재까지 국제적인 표준으로 사용되고 있습니다.</Li>
                      <Li>Scope 1 배출량 : 조직경계 내에서 직접 발생하는 직접 배출로 아래와 같은 배출원이 해당됩니다.</Li>
                    </ul>
                    <div style={{ backgroundColor: '#F9F9F9', fontSize: '18px', color: '#757575', borderRadius: '15px', padding: '26px' }}>
                      <div style={{ marginBottom: '5px' }}>[Scope 1 종류]</div>
                      <ol type="1">
                        <Li>고정연소 - 도시가스, 가스보일러, 등유 보일러, 발전기, 비상발전기, 소방펌프 등에서 발생하는 배출량</Li>
                        <Li>이동연소 - 도로/선박/항공/철도 등에서 발생하는 배출량</Li>
                        <Li>탈루배출 - 냉매, 화석연료 생산, 저장, 운동에서의 CH4 탈루 등에서 발생하는 배출량</Li>
                        <Li>공정배출 - 시멘트 공정, 화학 공정 등 제품 생산공정에서 원료의 물리화학적 반응 등에 따라 발생하는 배출량</Li>
                        <Li>폐기물 처리 - 직접적인 매립, 소각, 생화학적 처리에서 발생하는 배출량 </Li>
                        <Li>토지 이용(LULUCF) - 토지 이용 변화 및 임업에서 발생하는 배출량</Li>
                      </ol>
                    </div>

                    <ul>
                      <li style={{ marginBottom: '5px' }}>Scope 2 배출량 : 외부에서 공급받은 에너지(전기, 열 또는 스팀 등) 사용에 의한 간접 배출로 아래와 같은 배출원이 해당됩니다.</li>
                    </ul>
                    <div style={{ backgroundColor: '#F9F9F9', fontSize: '18px', color: '#757575', borderRadius: '15px', padding: '26px' }}>
                      <div style={{ marginBottom: '5px' }}>[Scope 2 종류]</div>
                      <ol type="1">
                        <Li>전기 사용 - 전력 사용/구매에 따른 간접 배출량</Li>
                        <Li>열(스팀) 사용 - 열(스팀) 사용/구매에 따른 간접 배출량</Li>
                      </ol>
                    </div>

                    <ul>
                      <Li>Scope 3 배출량 : 기업의 경제활동과 연관된 가치사슬에서 발생하는 간접 배출량을 의미하며, 공급망에서 구매한 물품 및 서비스에서부터 판매한 제품의 사용 및 폐기 과정까지 기업의 경제활동으로 인해 미치는 광범위한 범위의 온실가스 배출량으로 아래와 같은 15개 카테고리로 분류되어 있습니다.</Li>
                    </ul>
                    <div style={{ backgroundColor: '#F9F9F9', fontSize: '18px', color: '#757575', borderRadius: '15px', padding: '26px', marginBottom: '18px' }}>
                      <div style={{ marginBottom: '5px' }}>[Scope 3 Category]</div>
                      <ul>
                        <Li>Category 1. 원부자재 및 서비스 구매</Li>
                        <Li>Category 2. 자본재 투자 및 구매</Li>
                        <Li>Category 3. 연료 및 에너지 관련 활용</Li>
                        <Li>Category 4. 원부자재 운송</Li>
                        <Li>Category 5. 사업장 발생 폐기물</Li>
                        <Li>Category 6. 구성원 출장</Li>
                        <Li>Category 7. 구성원 통근</Li>
                        <Li>Category 8. 임차 자산</Li>
                        <Li>Category 9. 운송 및 유통</Li>
                        <Li>Category 10. 판매 제품의 가공</Li>
                        <Li>Category 11. 판매 제품의 사용</Li>
                        <Li>Category 12. 판매 제품의 폐기 처리</Li>
                        <Li>Category 13. 임대 자산</Li>
                        <Li>Category 14. 프랜차이즈</Li>
                        <Li>Category 15. 투자</Li>
                      </ul>
                    </div>
                  </div>
                </AccordionDetails>
              </Accordion>

              <Accordion disableGutters elevation={0} square style={{ borderBottom: '1px solid #EEEEEE' }}>
                <AccordionSummary expandIcon={<ExpandMore fontSize="large" />} >
                  <div style={{ fontSize: '24px', fontWeight: 600, padding: '20px 24px' }}>
                    <span style={{ color: '#00CD9B' }}>Q.</span> Scope 3 배출량은 어떻게 산정되나요?
                  </div>
                </AccordionSummary>
                <AccordionDetails>
                  <div style={{ fontSize: '18px', color: '#757575', margin: '0 30px' }}>
                    <ul>
                      <Li>이스코프는 기업의 상황에 맞춘 Scope 3 인벤토리와 지속적인 관리 방안을 구축합니다.</Li>
                      <Li>Scope 3 배출량 스크리닝을 통한 카테고리별 중대성 평가와 기업의 필요성에 맞춰 어떤 카테고리들을 먼저 관리해 나갈지 결정합니다. 이후 데이터 수집 체계 및 방법론 설정, 방법론에 따른 배출계수 연결까지 기업의 상황에 맞춘 Scope 3 배출량 산정 및 관리 방안을 구축합니다. 현재 수집가능한 데이터 종류에 따라 비용 기반 산정법, 제품 기반 산정법, 활동 기반 산정법 등 다양한 방법론을 적용하고 검증받은 다양한 국내외 Scope 3 배출계수 DB를 맞춤형으로 제공합니다.</Li>
                    </ul>
                  </div>
                </AccordionDetails>
              </Accordion>

              <Accordion disableGutters elevation={0} square style={{ borderBottom: '1px solid #EEEEEE' }}>
                <AccordionSummary expandIcon={<ExpandMore fontSize="large" />} >
                  <div style={{ fontSize: '24px', fontWeight: 600, padding: '20px 24px' }}>
                    <span style={{ color: '#00CD9B' }}>Q.</span> 제품별 탄소 배출량은 어떻게 산정되나요?
                  </div>
                </AccordionSummary>
                <AccordionDetails>
                  <div style={{ fontSize: '18px', color: '#757575', margin: '0 30px' }}>
                    <ul>
                      <Li>이스코프는 탄소 전문가 없이도 내부 인원이 자체적으로 수많은 제품별 탄소 배출량을 산정하고 시뮬레이션 해볼 수 있는 기능을 제공합니다. 기업은 이 결과를 이용해 제품에 대한 다양한 인증을 받거나 국내외 거래처에 공유하는 용도로 사용할 수 있습니다.</Li>
                      <Li>제조 전 과정, 운송 과정, 제조 과정, 유통 과정, 사용 및 폐기 과정에 이르기까지 제품 전주기에서 발생하는 탄소 배출량을 산정하여 cradle-to-gate, cradle-to-grave 제품별 탄소 배출량(PCF, Product Carbon Footprint)를 산정합니다.</Li>
                      <Li>또한 사용한 원자재를 바꾸거나 운송 과정을 변경 했을 때, 패키징 방법 및 재료를 변경 했을 때 등 예상 제품 탄소 배출량이 어떻게 바뀌는지도 시뮬레이션하고 확인할 수 있습니다.</Li>
                    </ul>
                  </div>
                </AccordionDetails>
              </Accordion>

              <Accordion disableGutters elevation={0} square >
                <AccordionSummary expandIcon={<ExpandMore fontSize="large" />}>
                  <div style={{ fontSize: '24px', fontWeight: 600, padding: '20px 24px' }}>
                    <span style={{ color: '#00CD9B' }}>Q.</span> 데이터 수집 자동화 파이프라인은 어떤 방식으로 구축되나요?
                  </div>
                </AccordionSummary>
                <AccordionDetails>
                  <div style={{ fontSize: '18px', color: '#757575', margin: '0 30px' }}>
                    <ul>
                      <Li>이스코프는 기업 상황에 맞춘 자동화된 데이터 수집 파이프라인을 구축합니다.</Li>
                      <Li>구매 기록, 에너지 사용, 물류 운송, 공정 배출, 재무적 투자 등 다양한 형태의 데이터를 내부 ERP 시스템, 외부 공공기관 시스템(한국전력 API, 올바로 API 등)과 연결되어 자동으로 수집해 Scope 1, 2 & 3 배출량으로 자동 산정하고 관리합니다.</Li>
                    </ul>
                  </div>
                </AccordionDetails>
              </Accordion>
            </div>
            <div style={{ height: '100px' }} />
          </>
          :
          <div style={{ width: '100%', padding: '50px 0', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <div style={{ width: '85%' }}>
              <div style={{ color: '#AAAAAA', fontSize: '14px', fontWeight: 600, display: 'flex', alignItems: 'center' }}>
                <span style={{ cursor: 'pointer' }} onClick={() => handleFaq()}>자주 묻는 질문</span>
                <img src="/images/faqchevron.png" style={{ height: '16px', margin: '0 2px' }} /> 배출량산정
              </div>
              <div style={{ height: '10px' }} />
              <Title2>배출량산정</Title2>
              <div style={{ height: '30px' }} />

              <div>
                <Accordion disableGutters elevation={0} square style={{ borderBottom: '1px solid #EEEEEE' }}>
                  <AccordionSummary expandIcon={<ExpandMore />} sx={{ padding: '0 10px' }}>
                    <div style={{ fontSize: '14px', fontWeight: 600, padding: '8px 10px', display: 'flex' }}>
                      <div style={{ color: '#00CD9B', marginRight: '6px' }}>Q.</div>
                      <div>온실가스 인벤토리란 무엇인가요?</div>
                    </div>
                  </AccordionSummary>
                  <AccordionDetails sx={{ padding: '0' }}>
                    <div style={{ fontSize: '18px', color: '#757575', margin: '-20px 40px 0 14px' }}>
                      <ul>
                        <Li>온실가스 인벤토리(GHG Inventory)란 특정 기간동안 조직의 온실가스 배출원/흡수원 별 배출량/흡수량을 목록화한 것을 의미합니다. 온실가스 인벤토리 구축을 위해서는</Li>
                      </ul>
                      <div style={{ backgroundColor: '#F9F9F9', fontSize: '18px', color: '#757575', borderRadius: '15px', padding: '26px' }}>
                        <ol type="a">
                          <Li>조직 경계 설정 - 온실가스 배출량을 산정하고 인벤토리를 작성할 경계를 식별</Li>
                          <Li>배출원 확인 및 분류 - 조직 경계 내 배출활동과 배출활동별 배출시설 확인 및 분류</Li>
                          <Li>활동자료 수집 - 배출시설별 활동자료 수집</Li>
                          <Li>배출량 산정 - 배출활동별 산정방법에 따라 최종 배출량 산정</Li>
                        </ol>
                      </div>
                      <div style={{ fontSize: '14px', color: '#757575', margin: '10px 0 20px 0' }}>위와 같은 과정을 수행해야 하며, 기업이 탄소중립 관리를 이제 처음 시작한다면 가장 먼저 해야할 일이 ‘온실가스 인벤토리 구축' 이기도 합니다.</div>
                    </div>
                  </AccordionDetails>
                </Accordion>

                <Accordion disableGutters elevation={0} square style={{ borderBottom: '1px solid #EEEEEE' }}>
                  <AccordionSummary expandIcon={<ExpandMore />} sx={{ padding: '0 10px' }} >
                    <div style={{ fontSize: '14px', fontWeight: 600, padding: '8px 10px', display: 'flex' }}>
                      <div style={{ color: '#00CD9B', marginRight: '6px' }}>Q.</div>
                      <div>탄소회계란 무엇인가요?</div>
                    </div>
                  </AccordionSummary>
                  <AccordionDetails sx={{ padding: '0' }}>
                    <div style={{ fontSize: '18px', color: '#757575', margin: '-20px 40px 0 14px' }}>
                      <ul>
                        <Li>탄소회계란 사업활동으로 인해 발생한 기업의 모든 직간접적인 탄소 배출량과 감축량을 기록해 데이터로 변환하는 일련의 프로세스를 칭합니다. 기업이 탄소 정보 공개를 수행하고 목표를 설정하고 탄소중립을 이행해나가기 위해서는 필수적인 과정입니다.</Li>
                      </ul>
                    </div>
                  </AccordionDetails>
                </Accordion>

                <Accordion disableGutters elevation={0} square style={{ borderBottom: '1px solid #EEEEEE' }}>
                  <AccordionSummary expandIcon={<ExpandMore />} sx={{ padding: '0 10px' }} >
                    <div style={{ fontSize: '14px', fontWeight: 600, padding: '8px 10px', display: 'flex' }}>
                      <div style={{ color: '#00CD9B', marginRight: '6px' }}>Q.</div>
                      <div>Scope 1, 2, 3 는 어떤 개념인가요?</div>
                    </div>
                  </AccordionSummary>
                  <AccordionDetails sx={{ padding: '0' }}>
                    <div style={{ fontSize: '18px', color: '#757575', margin: '-20px 40px 0 14px' }}>
                      <ul>
                        <Li>Scope 1, 2, 3 는 기업이 자체적으로 소유하고 운영하는 시설에서 발생하는 직간접 배출량과 기업의 업스트림, 다운스트림을 포괄하는 가치 사슬에서 발생하는 다양한 탄소배출원을 분류하는 방법입니다. 2001년 GHG protocol에서 처음 사용되었으며 현재까지 국제적인 표준으로 사용되고 있습니다.</Li>
                        <div style={{ height: '10px' }} />
                        <Li>Scope 1 배출량 : 조직경계 내에서 직접 발생하는 직접 배출로 아래와 같은 배출원이 해당됩니다.</Li>
                      </ul>
                      <div style={{ backgroundColor: '#F9F9F9', fontSize: '14px', color: '#757575', borderRadius: '15px', padding: '26px' }}>
                        <div style={{ marginBottom: '5px' }}>[Scope 1 종류]</div>
                        <ol type="1">
                          <Li>고정연소 - 도시가스, 가스보일러, 등유 보일러, 발전기, 비상발전기, 소방펌프 등에서 발생하는 배출량</Li>
                          <Li>이동연소 - 도로/선박/항공/철도 등에서 발생하는 배출량</Li>
                          <Li>탈루배출 - 냉매, 화석연료 생산, 저장, 운동에서의 CH4 탈루 등에서 발생하는 배출량</Li>
                          <Li>공정배출 - 시멘트 공정, 화학 공정 등 제품 생산공정에서 원료의 물리화학적 반응 등에 따라 발생하는 배출량</Li>
                          <Li>폐기물 처리 - 직접적인 매립, 소각, 생화학적 처리에서 발생하는 배출량 </Li>
                          <Li>토지 이용(LULUCF) - 토지 이용 변화 및 임업에서 발생하는 배출량</Li>
                        </ol>
                      </div>

                      <ul>
                        <Li>Scope 2 배출량 : 외부에서 공급받은 에너지(전기, 열 또는 스팀 등) 사용에 의한 간접 배출로 아래와 같은 배출원이 해당됩니다.</Li>
                      </ul>
                      <div style={{ backgroundColor: '#F9F9F9', fontSize: '14px', color: '#757575', borderRadius: '15px', padding: '26px' }}>
                        <div style={{ marginBottom: '5px' }}>[Scope 2 종류]</div>
                        <ol type="1">
                          <Li>전기 사용 - 전력 사용/구매에 따른 간접 배출량</Li>
                          <Li>열(스팀) 사용 - 열(스팀) 사용/구매에 따른 간접 배출량</Li>
                        </ol>
                      </div>

                      <ul>
                        <Li>Scope 3 배출량 : 기업의 경제활동과 연관된 가치사슬에서 발생하는 간접 배출량을 의미하며, 공급망에서 구매한 물품 및 서비스에서부터 판매한 제품의 사용 및 폐기 과정까지 기업의 경제활동으로 인해 미치는 광범위한 범위의 온실가스 배출량으로 아래와 같은 15개 카테고리로 분류되어 있습니다.</Li>
                      </ul>
                      <div style={{ backgroundColor: '#F9F9F9', fontSize: '14px', color: '#757575', borderRadius: '15px', padding: '26px' }}>
                        <div style={{ marginBottom: '5px' }}>[Scope 3 Category]</div>
                        <ul>
                          <Li>Category 1. 원부자재 및 서비스 구매</Li>
                          <Li>Category 2. 자본재 투자 및 구매</Li>
                          <Li>Category 3. 연료 및 에너지 관련 활용</Li>
                          <Li>Category 4. 원부자재 운송</Li>
                          <Li>Category 5. 사업장 발생 폐기물</Li>
                          <Li>Category 6. 구성원 출장</Li>
                          <Li>Category 7. 구성원 통근</Li>
                          <Li>Category 8. 임차 자산</Li>
                          <Li>Category 9. 운송 및 유통</Li>
                          <Li>Category 10. 판매 제품의 가공</Li>
                          <Li>Category 11. 판매 제품의 사용</Li>
                          <Li>Category 12. 판매 제품의 폐기 처리</Li>
                          <Li>Category 13. 임대 자산</Li>
                          <Li>Category 14. 프랜차이즈</Li>
                          <Li>Category 15. 투자</Li>
                        </ul>
                      </div>
                      <div style={{ height: '20px' }} />
                    </div>
                  </AccordionDetails>
                </Accordion>

                <Accordion disableGutters elevation={0} square style={{ borderBottom: '1px solid #EEEEEE' }}>
                  <AccordionSummary expandIcon={<ExpandMore />} sx={{ padding: '0 10px' }} >
                    <div style={{ fontSize: '14px', fontWeight: 600, padding: '8px 10px', display: 'flex' }}>
                      <div style={{ color: '#00CD9B', marginRight: '6px' }}>Q.</div>
                      <div>Scope 3 배출량은 어떻게 산정되나요?</div>
                    </div>
                  </AccordionSummary>
                  <AccordionDetails sx={{ padding: '0' }}>
                    <div style={{ fontSize: '18px', color: '#757575', margin: '-20px 40px 0 14px' }}>
                      <ul>
                        <Li>이스코프는 기업의 상황에 맞춘 Scope 3 인벤토리와 지속적인 관리 방안을 구축합니다.</Li>
                        <div style={{ height: '10px' }} />
                        <Li>Scope 3 배출량 스크리닝을 통한 카테고리별 중대성 평가와 기업의 필요성에 맞춰 어떤 카테고리들을 먼저 관리해 나갈지 결정합니다. 이후 데이터 수집 체계 및 방법론 설정, 방법론에 따른 배출계수 연결까지 기업의 상황에 맞춘 Scope 3 배출량 산정 및 관리 방안을 구축합니다. 현재 수집가능한 데이터 종류에 따라 비용 기반 산정법, 제품 기반 산정법, 활동 기반 산정법 등 다양한 방법론을 적용하고 검증받은 다양한 국내외 Scope 3 배출계수 DB를 맞춤형으로 제공합니다.</Li>
                      </ul>
                    </div>
                  </AccordionDetails>
                </Accordion>

                <Accordion disableGutters elevation={0} square style={{ borderBottom: '1px solid #EEEEEE' }}>
                  <AccordionSummary expandIcon={<ExpandMore />} sx={{ padding: '0 10px' }} >
                    <div style={{ fontSize: '14px', fontWeight: 600, padding: '8px 10px', display: 'flex' }}>
                      <div style={{ color: '#00CD9B', marginRight: '6px' }}>Q.</div>
                      <div>제품별 탄소 배출량은 어떻게 산정되나요?</div>
                    </div>
                  </AccordionSummary>
                  <AccordionDetails sx={{ padding: '0' }}>
                    <div style={{ fontSize: '18px', color: '#757575', margin: '-20px 40px 0 14px' }}>
                      <ul>
                        <Li>이스코프는 탄소 전문가 없이도 내부 인원이 자체적으로 수많은 제품별 탄소 배출량을 산정하고 시뮬레이션 해볼 수 있는 기능을 제공합니다. 기업은 이 결과를 이용해 제품에 대한 다양한 인증을 받거나 국내외 거래처에 공유하는 용도로 사용할 수 있습니다.</Li>
                        <div style={{ height: '10px' }} />
                        <Li>제조 전 과정, 운송 과정, 제조 과정, 유통 과정, 사용 및 폐기 과정에 이르기까지 제품 전주기에서 발생하는 탄소 배출량을 산정하여 cradle-to-gate, cradle-to-grave 제품별 탄소 배출량(PCF, Product Carbon Footprint)를 산정합니다.</Li>
                        <div style={{ height: '10px' }} />
                        <Li>또한 사용한 원자재를 바꾸거나 운송 과정을 변경 했을 때, 패키징 방법 및 재료를 변경 했을 때 등 예상 제품 탄소 배출량이 어떻게 바뀌는지도 시뮬레이션하고 확인할 수 있습니다.</Li>
                      </ul>
                    </div>
                  </AccordionDetails>
                </Accordion>

                <Accordion disableGutters elevation={0} square>
                  <AccordionSummary expandIcon={<ExpandMore />} sx={{ padding: '0 10px' }} >
                    <div style={{ fontSize: '14px', fontWeight: 600, padding: '8px 10px', display: 'flex' }}>
                      <div style={{ color: '#00CD9B', marginRight: '6px' }}>Q.</div>
                      <div>데이터 수집 자동화 파이프라인은 어떤 방식으로 구축되나요?</div>
                    </div>
                  </AccordionSummary>
                  <AccordionDetails sx={{ padding: '0' }}>
                    <div style={{ fontSize: '18px', color: '#757575', margin: '-20px 40px 0 14px' }}>
                      <ul>
                        <Li>이스코프는 기업 상황에 맞춘 자동화된 데이터 수집 파이프라인을 구축합니다.</Li>
                        <div style={{ height: '10px' }} />
                        <Li>구매 기록, 에너지 사용, 물류 운송, 공정 배출, 재무적 투자 등 다양한 형태의 데이터를 내부 ERP 시스템, 외부 공공기관 시스템(한국전력 API, 올바로 API 등)과 연결되어 자동으로 수집해 Scope 1, 2 & 3 배출량으로 자동 산정하고 관리합니다.</Li>
                      </ul>
                    </div>
                  </AccordionDetails>
                </Accordion>
              </div>
            </div>
          </div>
        }
        
      </div>
    </div>
  )
};

export default Emission;
