import { useRef, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Accordion, AccordionSummary, AccordionDetails } from "@mui/material";
import ExpandMore from "@mui/icons-material/ExpandMore";
import { Title2, Li } from "../components/Font";
import './questions.css';

const Etc = props => {
  const element = useRef();
  const orgScrollHeight = useRef(document.documentElement.scrollHeight);
  const [contentHeight, setContentHeight] = useState(0);
  const navigate = useNavigate();

  const handleFaq = () => {
    if ("pHistory" in props && props.pHistory.current[props.pHistory.current.length - 2] == "/faq") {
      navigate(-1);
    }
    else {
      navigate("/faq");
    }
  }
  
  useEffect(() => {
    const resize = new ResizeObserver(entries => {
      for (const entry of entries) {
        const { width, height } = entry.contentRect;
        setContentHeight(height);
      }
    });
    if (element.current) {
      resize.observe(element.current);
    }

    return () => {
      resize.disconnect();
    };
  }, [element]);

  useEffect(() => {
    if (orgScrollHeight.current <= contentHeight + 400) {
      props.disableFillHeight();
    }
    else {
      props.enableFillHeight();
    }
  }, [contentHeight]);

  return (
    <div style={{ display: 'flex', flex: 1, justifyContent: 'center', alignItems: 'center' }} >
      <div ref={element}>
        {props.innerWidth > 768 ?
          <>
            <div style={{ height: '100px' }} />
            <div style={{ color: '#AAAAAA', fontSize: '18px', fontWeight: 600, display: 'flex', alignItems: 'center' }}>
              <span style={{ cursor: 'pointer' }} onClick={() => handleFaq()}>자주 묻는 질문</span>
              <img src="/images/faqchevron.png" style={{ height: '21px', margin: '0 6px' }} /> 기타 문의
            </div>
            <div style={{ height: '20px' }} />
            <div style={{ fontSize: '42px', fontWeight: 'bold' }}>기타 문의</div>
            <div style={{ height: '40px' }} />

            <div style={{ maxWidth: '1300px' }}>
              <Accordion disableGutters elevation={0} square style={{ borderBottom: '1px solid #EEEEEE' }}>
                <AccordionSummary expandIcon={<ExpandMore fontSize="large" />} >
                  <div style={{ fontSize: '24px', fontWeight: 600, padding: '20px 24px' }}>
                    <span style={{ color: '#00CD9B' }}>Q.</span> ESG 공시를 위한 데이터 수집과 관리도 이스코프를 활용해서 할 수 있나요?
                  </div>
                </AccordionSummary>
                <AccordionDetails>
                  <div style={{ fontSize: '18px', color: '#757575', margin: '0 30px' }}>
                    <ul>
                      <Li>이스코프를 도입하며 수립한 전사적 탄소 데이터 취합/관리 시스템을 그대로 활용해 MSCI, KCGS, GRI 등 공시 표준에 따른 ESG데이터를 관리자가 손쉽게 취합/검증하고 지속가능한 방식으로 관리할 수 있는 기능을 추가적으로 제공하고 있습니다.</Li>
                      <Li>관리자는 클릭 몇 번으로 지정된 입력 담당자들에게 분기/반기별로 ESG 데이터를 요청할 수 있고 입력 담당자는 ESG 데이터 입력에 표준화된 입력창을 통해 데이터를 입력하고 승인 결재를 올릴 수 있습니다.</Li>
                    </ul>
                  </div>
                </AccordionDetails>
              </Accordion>

              <Accordion disableGutters elevation={0} square>
                <AccordionSummary expandIcon={<ExpandMore fontSize="large" />}>
                  <div style={{ fontSize: '24px', fontWeight: 600, padding: '20px 24px' }}>
                    <span style={{ color: '#00CD9B' }}>Q.</span> 이스코프를 사용해서 데이터를 수집하고 관리하는건 안전한가요?
                  </div>
                </AccordionSummary>
                <AccordionDetails>
                  <div style={{ fontSize: '18px', color: '#757575', margin: '0 30px' }}>
                    <ul>
                      <Li>이스코프는 고객 여러분이 안심하고 서비스를 사용할 수 있도록 높은 보안수준을 유지하고 있으며 보안강화를 위해 지속적으로 노력하고 있습니다.</Li>
                      <Li>모든데이터에 블럭체인을 적용하고 또한 데이터에 대한 암호화도 진행을 하고 있습니다.</Li>
                    </ul>
                  </div>
                </AccordionDetails>
              </Accordion>
            </div>
            <div style={{ height: '100px' }} />
          </>
          :
          <div style={{ width: '100%', padding: '50px 0', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <div style={{ width: '85%' }}>
              <div style={{ color: '#AAAAAA', fontSize: '14px', fontWeight: 600, display: 'flex', alignItems: 'center' }}>
                <span style={{ cursor: 'pointer' }} onClick={() => handleFaq()}>자주 묻는 질문</span>
                <img src="/images/faqchevron.png" style={{ height: '16px', margin: '0 2px' }} /> 기타 문의
              </div>
              <div style={{ height: '10px' }} />
              <Title2>기타 문의</Title2>
              <div style={{ height: '30px' }} />

              <div>
                <Accordion disableGutters elevation={0} square style={{ borderBottom: '1px solid #EEEEEE' }}>
                  <AccordionSummary expandIcon={<ExpandMore />} sx={{ padding: '0 10px' }} >
                    <div style={{ fontSize: '14px', fontWeight: 600, padding: '8px 10px', display: 'flex' }}>
                      <div style={{ color: '#00CD9B', marginRight: '6px' }}>Q.</div>
                      <div>ESG 공시를 위한 데이터 수집과 관리도 이스코프를 활용해서 할 수 있나요?</div>
                    </div>
                  </AccordionSummary>
                  <AccordionDetails sx={{ padding: '0' }}>
                    <div style={{ fontSize: '18px', color: '#757575', margin: '-20px 40px 0 14px' }}>
                      <ul>
                        <Li>이스코프를 도입하며 수립한 전사적 탄소 데이터 취합/관리 시스템을 그대로 활용해 MSCI, KCGS, GRI 등 공시 표준에 따른 ESG데이터를 관리자가 손쉽게 취합/검증하고 지속가능한 방식으로 관리할 수 있는 기능을 추가적으로 제공하고 있습니다.</Li>
                        <div style={{ height: '10px' }} />
                        <Li>관리자는 클릭 몇 번으로 지정된 입력 담당자들에게 분기/반기별로 ESG 데이터를 요청할 수 있고 입력 담당자는 ESG 데이터 입력에 표준화된 입력창을 통해 데이터를 입력하고 승인 결재를 올릴 수 있습니다.</Li>
                      </ul>
                    </div>
                  </AccordionDetails>
                </Accordion>

                <Accordion disableGutters elevation={0} square >
                  <AccordionSummary expandIcon={<ExpandMore />} sx={{ padding: '0 10px' }} >
                    <div style={{ fontSize: '14px', fontWeight: 600, padding: '8px 10px', display: 'flex' }}>
                      <div style={{ color: '#00CD9B', marginRight: '6px' }}>Q.</div>
                      <div>이스코프를 사용해서 데이터를 수집하고 관리하는건 안전한가요?</div>
                    </div>
                  </AccordionSummary>
                  <AccordionDetails sx={{ padding: '0' }}>
                    <div style={{ fontSize: '18px', color: '#757575', margin: '-20px 40px 0 14px' }}>
                      <ul>
                        <Li>이스코프는 고객 여러분이 안심하고 서비스를 사용할 수 있도록 높은 보안수준을 유지하고 있으며 보안강화를 위해 지속적으로 노력하고 있습니다.</Li>
                        <div style={{ height: '10px' }} />
                        <Li>모든데이터에 블럭체인을 적용하고 또한 데이터에 대한 암호화도 진행을 하고 있습니다.</Li>
                      </ul>
                    </div>
                  </AccordionDetails>
                </Accordion>
              </div>
            </div>
          </div>
        }
      </div>
    </div>
  )
};

export default Etc;
