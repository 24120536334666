import { useRef, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Accordion, AccordionSummary, AccordionDetails } from "@mui/material";
import ExpandMore from "@mui/icons-material/ExpandMore";
import { Title2, Li } from "../components/Font";
import './questions.css';

const Roadmap = props => {
  const element = useRef();
  const orgScrollHeight = useRef(document.documentElement.scrollHeight);
  const [contentHeight, setContentHeight] = useState(0);
  const navigate = useNavigate();

  const handleFaq = () => {
    if ("pHistory" in props && props.pHistory.current[props.pHistory.current.length - 2] == "/faq") {
      navigate(-1);
    }
    else {
      navigate("/faq");
    }
  }

  useEffect(() => {
    const resize = new ResizeObserver(entries => {
      for (const entry of entries) {
        const { width, height } = entry.contentRect;
        setContentHeight(height);
      }
    });
    if (element.current) {
      resize.observe(element.current);
    }

    return () => {
      resize.disconnect();
    };
  }, [element]);

  useEffect(() => {
    if (orgScrollHeight.current <= contentHeight + 400) {
      props.disableFillHeight();
    }
    else {
      props.enableFillHeight();
    }
  }, [contentHeight]);

  return (
    <div style={{ display: 'flex', flex: 1, justifyContent: 'center', alignItems: 'center' }} >
      <div ref={element}>
        {props.innerWidth > 768 ?
          <>
            <div style={{ height: '100px' }} />
            <div style={{ color: '#AAAAAA', fontSize: '18px', fontWeight: 600, display: 'flex', alignItems: 'center' }}>
              <span style={{ cursor: 'pointer' }} onClick={() => handleFaq()}>자주 묻는 질문</span>
              <img src="/images/faqchevron.png" style={{ height: '21px', margin: '0 6px' }} /> 탄소중립 로드맵
            </div>
            <div style={{ height: '20px' }} />
            <div style={{ fontSize: '42px', fontWeight: 'bold' }}>탄소중립 로드맵</div>
            <div style={{ height: '40px' }} />

            <div style={{ maxWidth: '1300px' }}>
              <Accordion disableGutters elevation={0} square style={{ borderBottom: '1px solid #EEEEEE' }}>
                <AccordionSummary expandIcon={<ExpandMore fontSize="large" />} >
                  <div style={{ fontSize: '24px', fontWeight: 600, padding: '20px 24px' }}>
                    <span style={{ color: '#00CD9B' }}>Q.</span> 탄소중립 로드맵이란 무엇인가요?
                  </div>
                </AccordionSummary>
                <AccordionDetails>
                  <div style={{ fontSize: '18px', color: '#757575', margin: '0 30px' }}>
                    <ul>
                      <Li>일반적으로 탄소중립 로드맵은 앞으로 기업의 배출량이 어떻게 변화할 것이고(BAU) 현재 어떤 감축 목표를 가지고 있으며(예. SBTi에 기반한 1.5도 감축 목표) 그에 따라 요구되는 감축량을 어떠한 방안을 이용해 실제 감축해 나갈 것인지(감축 방안 long-list)에 대한 전체적인 로드맵을 의미합니다.</Li>
                    </ul>
                  </div>
                </AccordionDetails>
              </Accordion>

              <Accordion disableGutters elevation={0} square style={{ borderBottom: '1px solid #EEEEEE' }}>
                <AccordionSummary expandIcon={<ExpandMore fontSize="large" />}>
                  <div style={{ fontSize: '24px', fontWeight: 600, padding: '20px 24px' }}>
                    <span style={{ color: '#00CD9B' }}>Q.</span> 탄소중립 로드맵 수립은 어떻게 진행되나요?
                  </div>
                </AccordionSummary>
                <AccordionDetails>
                  <div style={{ fontSize: '18px', color: '#757575', margin: '0 30px' }}>
                    <ul>
                      <Li>데이터에 기반한 맞춤형 탄소중립 로드맵을 고객과의 인터뷰를 통해 수립합니다.</Li>
                      <Li>기업이 속한 산업의 산업성장률(CAGR), 사업확장 계획 및 매출 성장목표 등을 기반으로 BAU를 산정하고 SBTi(Science Based Targets Initiative)에 기반한 단기적/장기적 과학적 감축 목표를 설정합니다.이를 통해 연도별 목표 감축량을 산정하고 그에 따라 Scope 범위 별로 다양한 종류(Reduce, Switch, Innovation 및 Offset)의 감축방안 long-list를 도출합니다. 도출된 감축 방안은 각 방안에 따라 감축 효과, 비용, 기술 현황 등을 조사해 고객에게 제공합니다.</Li>
                    </ul>
                  </div>
                </AccordionDetails>
              </Accordion>

              <Accordion disableGutters elevation={0} square style={{ borderBottom: '1px solid #EEEEEE' }}>
                <AccordionSummary expandIcon={<ExpandMore fontSize="large" />}>
                  <div style={{ fontSize: '24px', fontWeight: 600, padding: '20px 24px' }}>
                    <span style={{ color: '#00CD9B' }}>Q.</span> 감축 목표는 어떻게 설정하게 되나요?
                  </div>
                </AccordionSummary>
                <AccordionDetails>
                  <div style={{ fontSize: '18px', color: '#757575', margin: '0 30px' }}>
                    <ul>
                      <Li>기업의 상황과 특성에 따라 Scope 1, 2 와 Scope 3 목표를 나눠서 설정하게 되며 2℃ / 2℃ well-below/ 1.5℃ 시나리오 등 기업의 상황에 따른 시나리오를 수립하게 됩니다.</Li>
                      <Li>Scope 1, 2 의 경우 CDP, SBTi 및 ISSB와 같은 기후 공시에서 필수적으로 요구하고 있으며 Scope 3의 경우 일부를 커버하거나 보다 낮은 수준의 목표를 설정해도 인정 받고 있습니다.</Li>
                    </ul>
                  </div>
                </AccordionDetails>
              </Accordion>

              <Accordion disableGutters elevation={0} square style={{ borderBottom: '1px solid #EEEEEE' }}>
                <AccordionSummary expandIcon={<ExpandMore fontSize="large" />}>
                  <div style={{ fontSize: '24px', fontWeight: 600, padding: '20px 24px' }}>
                    <span style={{ color: '#00CD9B' }}>Q.</span> SBTi란 무엇인가요?
                  </div>
                </AccordionSummary>
                <AccordionDetails>
                  <div style={{ fontSize: '18px', color: '#757575', margin: '0 30px' }}>
                    <ul>
                      <Li>과학기반감축목표이니셔티브 (Science-based Target Initiative, SBTi)는 기업 및 금융기관이 파리기후협약을 달성할 수 있는 탄소 감축 목표 기준을 제시하고 모니터링 하는 이니셔티브입니다.</Li>
                      <Li>현재 글로벌적으로 탄소 감축 목표를 설정하는 데에 가장 일반적으로 쓰이는 이니셔티브이며 전 세계 1,700개가 넘는 기업이 이미 가입해 매년 감축 실적을 인증하고 있습니다. 일부 유럽 및 북미 기업의 경우 글로벌 공급망 협력사에게 SBTi 인증을 거래조건으로 요구하는 경우도 늘어나고 있는 추세입니다.</Li>
                    </ul>
                  </div>
                </AccordionDetails>
              </Accordion>

              <Accordion disableGutters elevation={0} square style={{ borderBottom: '1px solid #EEEEEE' }}>
                <AccordionSummary expandIcon={<ExpandMore fontSize="large" />}>
                  <div style={{ fontSize: '24px', fontWeight: 600, padding: '20px 24px' }}>
                    <span style={{ color: '#00CD9B' }}>Q.</span> RE100 로드맵은 어떻게 수립되나요?
                  </div>
                </AccordionSummary>
                <AccordionDetails>
                  <div style={{ fontSize: '18px', color: '#757575', margin: '0 30px' }}>
                    <ul>
                      <Li>저희와 함께 기업은 RE100 목표를 설정하고 이행방안을 시뮬레이션하고 실적을 관리할 수 있습니다.</Li>
                      <Li>기업이 RE100을 이행할 수 있는 방안은 크게 자가발전, PPA, REC 구매, 녹색프리미엄과 같은 방법들이 있습니다.</Li>
                      <Li>기업은 배출량 산정시 수집된 전력 사용 데이터와 연동해 다양한 이행방안별 감축효과와 비용을 시뮬레이션 해보고 기업 상황에 따라 비용 관점 혹은 감축 효과 관점 등에서 최적의 RE100 로드맵을 수립하게 됩니다.</Li>
                      <Li>이후 기업은 RE100 이행 실적을 엔스코프에 데이터로 등록하고 RE100 이행상황을 지속가능하게 관리해 나갈 수 있습니다</Li>
                    </ul>
                  </div>
                </AccordionDetails>
              </Accordion>

              <Accordion disableGutters elevation={0} square style={{ borderBottom: '1px solid #EEEEEE' }}>
                <AccordionSummary expandIcon={<ExpandMore fontSize="large" />}>
                  <div style={{ fontSize: '24px', fontWeight: 600, padding: '20px 24px' }}>
                    <span style={{ color: '#00CD9B' }}>Q.</span> 앞으로의 탄소 배출량 예상값(BAU)은 어떻게 산정하나요?
                  </div>
                </AccordionSummary>
                <AccordionDetails>
                  <div style={{ fontSize: '18px', color: '#757575', margin: '0 30px' }}>
                    <ul>
                      <Li>탄소중립 로드맵 수립을 위한 첫 번째 단계로 BAU(Business-as-Usual Emission Projection)를 산정하게 됩니다.</Li>
                      <Li>기업에 상황에 맞춰 복합적인 방법론을 적용하게 되며 가장 대표적으로는 과거 및 현재의 배출량과 기업의 사업 확장 계획 및 현황, 기업이 속한 산업의 산업성장률 지표, 매출 성장 목표 및 제품 생산 목표 등을 조합하여 계산하게 됩니다.</Li>
                    </ul>
                  </div>
                </AccordionDetails>
              </Accordion>

              <Accordion disableGutters elevation={0} square>
                <AccordionSummary expandIcon={<ExpandMore fontSize="large" />}>
                  <div style={{ fontSize: '24px', fontWeight: 600, padding: '20px 24px' }}>
                    <span style={{ color: '#00CD9B' }}>Q.</span> 감축 방안 수립은 어떻게 진행되나요?
                  </div>
                </AccordionSummary>
                <AccordionDetails>
                  <div style={{ fontSize: '18px', color: '#757575', margin: '0 30px' }}>
                    <ul>
                      <Li>기업의 상황에 따라 Scope 범위 별로 Reduce, Switch, Innovation 및 Offset 분류에 해당하는 감축 방안을 도출합니다.</Li>
                    </ul>
                  </div>
                </AccordionDetails>
              </Accordion>
            </div>
            <div style={{ height: '100px' }} />
          </>
          :
          <div style={{ width: '100%', padding: '50px 0', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <div style={{ width: '85%' }}>
              <div style={{ color: '#AAAAAA', fontSize: '14px', fontWeight: 600, display: 'flex', alignItems: 'center' }}>
                <span style={{ cursor: 'pointer' }} onClick={() => handleFaq()}>자주 묻는 질문</span>
                <img src="/images/faqchevron.png" style={{ height: '16px', margin: '0 2px' }} /> 탄소중립 로드맵
              </div>
              <div style={{ height: '10px' }} />
              <Title2>탄소중립 로드맵</Title2>
              <div style={{ height: '30px' }} />

              <div>
                <Accordion disableGutters elevation={0} square style={{ borderBottom: '1px solid #EEEEEE' }}>
                  <AccordionSummary expandIcon={<ExpandMore />} sx={{ padding: '0 10px' }} >
                    <div style={{ fontSize: '14px', fontWeight: 600, padding: '8px 10px', display: 'flex' }}>
                      <div style={{ color: '#00CD9B', marginRight: '6px' }}>Q.</div>
                      <div>탄소중립 로드맵이란 무엇인가요?</div>
                    </div>
                  </AccordionSummary>
                  <AccordionDetails sx={{ padding: '0' }}>
                    <div style={{ fontSize: '18px', color: '#757575', margin: '-20px 40px 0 14px' }}>
                      <ul>
                        <Li>일반적으로 탄소중립 로드맵은 앞으로 기업의 배출량이 어떻게 변화할 것이고(BAU) 현재 어떤 감축 목표를 가지고 있으며(예. SBTi에 기반한 1.5도 감축 목표) 그에 따라 요구되는 감축량을 어떠한 방안을 이용해 실제 감축해 나갈 것인지(감축 방안 long-list)에 대한 전체적인 로드맵을 의미합니다.</Li>
                      </ul>
                    </div>
                  </AccordionDetails>
                </Accordion>

                <Accordion disableGutters elevation={0} square style={{ borderBottom: '1px solid #EEEEEE' }}>
                  <AccordionSummary expandIcon={<ExpandMore />} sx={{ padding: '0 10px' }} >
                    <div style={{ fontSize: '14px', fontWeight: 600, padding: '8px 10px', display: 'flex' }}>
                      <div style={{ color: '#00CD9B', marginRight: '6px' }}>Q.</div>
                      <div>탄소중립 로드맵 수립은 어떻게 진행되나요?</div>
                    </div>
                  </AccordionSummary>
                  <AccordionDetails sx={{ padding: '0' }}>
                    <div style={{ fontSize: '18px', color: '#757575', margin: '-20px 40px 0 14px' }}>
                      <ul>
                        <Li>데이터에 기반한 맞춤형 탄소중립 로드맵을 고객과의 인터뷰를 통해 수립합니다.</Li>
                        <div style={{ height: '10px' }} />
                        <Li>기업이 속한 산업의 산업성장률(CAGR), 사업확장 계획 및 매출 성장목표 등을 기반으로 BAU를 산정하고 SBTi(Science Based Targets Initiative)에 기반한 단기적/장기적 과학적 감축 목표를 설정합니다.이를 통해 연도별 목표 감축량을 산정하고 그에 따라 Scope 범위 별로 다양한 종류(Reduce, Switch, Innovation 및 Offset)의 감축방안 long-list를 도출합니다. 도출된 감축 방안은 각 방안에 따라 감축 효과, 비용, 기술 현황 등을 조사해 고객에게 제공합니다.</Li>
                      </ul>
                    </div>
                  </AccordionDetails>
                </Accordion>

                <Accordion disableGutters elevation={0} square style={{ borderBottom: '1px solid #EEEEEE' }}>
                  <AccordionSummary expandIcon={<ExpandMore />} sx={{ padding: '0 10px' }} >
                    <div style={{ fontSize: '14px', fontWeight: 600, padding: '8px 10px', display: 'flex' }}>
                      <div style={{ color: '#00CD9B', marginRight: '6px' }}>Q.</div>
                      <div>감축 목표는 어떻게 설정하게 되나요?</div>
                    </div>
                  </AccordionSummary>
                  <AccordionDetails sx={{ padding: '0' }}>
                    <div style={{ fontSize: '18px', color: '#757575', margin: '-20px 40px 0 14px' }}>
                      <ul>
                        <Li>기업의 상황과 특성에 따라 Scope 1, 2 와 Scope 3 목표를 나눠서 설정하게 되며 2℃ / 2℃ well-below/ 1.5℃ 시나리오 등 기업의 상황에 따른 시나리오를 수립하게 됩니다.</Li>
                        <div style={{ height: '10px' }} />
                        <Li>Scope 1, 2 의 경우 CDP, SBTi 및 ISSB와 같은 기후 공시에서 필수적으로 요구하고 있으며 Scope 3의 경우 일부를 커버하거나 보다 낮은 수준의 목표를 설정해도 인정 받고 있습니다.</Li>
                      </ul>
                    </div>
                  </AccordionDetails>
                </Accordion>

                <Accordion disableGutters elevation={0} square style={{ borderBottom: '1px solid #EEEEEE' }}>
                  <AccordionSummary expandIcon={<ExpandMore />} sx={{ padding: '0 10px' }} >
                    <div style={{ fontSize: '14px', fontWeight: 600, padding: '8px 10px', display: 'flex' }}>
                      <div style={{ color: '#00CD9B', marginRight: '6px' }}>Q.</div>
                      <div>SBTi란 무엇인가요?</div>
                    </div>
                  </AccordionSummary>
                  <AccordionDetails sx={{ padding: '0' }}>
                    <div style={{ fontSize: '18px', color: '#757575', margin: '-20px 40px 0 14px' }}>
                      <ul>
                        <Li>과학기반감축목표이니셔티브 (Science-based Target Initiative, SBTi)는 기업 및 금융기관이 파리기후협약을 달성할 수 있는 탄소 감축 목표 기준을 제시하고 모니터링 하는 이니셔티브입니다.</Li>
                        <div style={{ height: '10px' }} />
                        <Li>현재 글로벌적으로 탄소 감축 목표를 설정하는 데에 가장 일반적으로 쓰이는 이니셔티브이며 전 세계 1,700개가 넘는 기업이 이미 가입해 매년 감축 실적을 인증하고 있습니다. 일부 유럽 및 북미 기업의 경우 글로벌 공급망 협력사에게 SBTi 인증을 거래조건으로 요구하는 경우도 늘어나고 있는 추세입니다.</Li>
                      </ul>
                    </div>
                  </AccordionDetails>
                </Accordion>

                <Accordion disableGutters elevation={0} square style={{ borderBottom: '1px solid #EEEEEE' }}>
                  <AccordionSummary expandIcon={<ExpandMore />} sx={{ padding: '0 10px' }} >
                    <div style={{ fontSize: '14px', fontWeight: 600, padding: '8px 10px', display: 'flex' }}>
                      <div style={{ color: '#00CD9B', marginRight: '6px' }}>Q.</div>
                      <div>RE100 로드맵은 어떻게 수립되나요?</div>
                    </div>
                  </AccordionSummary>
                  <AccordionDetails sx={{ padding: '0' }}>
                    <div style={{ fontSize: '18px', color: '#757575', margin: '-20px 40px 0 14px' }}>
                      <ul>
                        <Li>저희와 함께 기업은 RE100 목표를 설정하고 이행방안을 시뮬레이션하고 실적을 관리할 수 있습니다.</Li>
                        <div style={{ height: '10px' }} />
                        <Li>기업이 RE100을 이행할 수 있는 방안은 크게 자가발전, PPA, REC 구매, 녹색프리미엄과 같은 방법들이 있습니다.</Li>
                        <div style={{ height: '10px' }} />
                        <Li>기업은 배출량 산정시 수집된 전력 사용 데이터와 연동해 다양한 이행방안별 감축효과와 비용을 시뮬레이션 해보고 기업 상황에 따라 비용 관점 혹은 감축 효과 관점 등에서 최적의 RE100 로드맵을 수립하게 됩니다.</Li>
                        <div style={{ height: '10px' }} />
                        <Li>이후 기업은 RE100 이행 실적을 엔스코프에 데이터로 등록하고 RE100 이행상황을 지속가능하게 관리해 나갈 수 있습니다</Li>
                      </ul>
                    </div>
                  </AccordionDetails>
                </Accordion>

                <Accordion disableGutters elevation={0} square style={{ borderBottom: '1px solid #EEEEEE' }}>
                  <AccordionSummary expandIcon={<ExpandMore />} sx={{ padding: '0 10px' }} >
                    <div style={{ fontSize: '14px', fontWeight: 600, padding: '8px 10px', display: 'flex' }}>
                      <div style={{ color: '#00CD9B', marginRight: '6px' }}>Q.</div>
                      <div>앞으로의 탄소 배출량 예상값(BAU)은 어떻게 산정하나요?</div>
                    </div>
                  </AccordionSummary>
                  <AccordionDetails sx={{ padding: '0' }}>
                    <div style={{ fontSize: '18px', color: '#757575', margin: '-20px 40px 0 14px' }}>
                      <ul>
                        <Li>탄소중립 로드맵 수립을 위한 첫 번째 단계로 BAU(Business-as-Usual Emission Projection)를 산정하게 됩니다.</Li>
                        <div style={{ height: '10px' }} />
                        <Li>기업에 상황에 맞춰 복합적인 방법론을 적용하게 되며 가장 대표적으로는 과거 및 현재의 배출량과 기업의 사업 확장 계획 및 현황, 기업이 속한 산업의 산업성장률 지표, 매출 성장 목표 및 제품 생산 목표 등을 조합하여 계산하게 됩니다.</Li>
                      </ul>
                    </div>
                  </AccordionDetails>
                </Accordion>

                <Accordion disableGutters elevation={0} square >
                  <AccordionSummary expandIcon={<ExpandMore />} sx={{ padding: '0 10px' }} >
                    <div style={{ fontSize: '14px', fontWeight: 600, padding: '8px 10px', display: 'flex' }}>
                      <div style={{ color: '#00CD9B', marginRight: '6px' }}>Q.</div>
                      <div>감축 방안 수립은 어떻게 진행되나요?</div>
                    </div>
                  </AccordionSummary>
                  <AccordionDetails sx={{ padding: '0' }}>
                    <div style={{ fontSize: '18px', color: '#757575', margin: '-20px 40px 0 14px' }}>
                      <ul>
                        <Li>기업의 상황에 따라 Scope 범위 별로 Reduce, Switch, Innovation 및 Offset 분류에 해당하는 감축 방안을 도출합니다.</Li>
                      </ul>
                    </div>
                  </AccordionDetails>
                </Accordion>
              </div>
              <div style={{ height: '100px' }} />
            </div>
          </div>
        }
      </div>
    </div>
  )
};

export default Roadmap;
