import { useState, useCallback, useEffect } from "react";
import { Collapse, Fade, styled } from "@mui/material";
import { useNavigate } from "react-router-dom";
import MenuIcon from '@mui/icons-material/Menu';
import Close from "@mui/icons-material/Close";

const HeaderContainer = styled('div')(() => ({
  display: 'flex',
  alignItems: 'center',
  '@media (max-width: 768px)': {
    height: '50px',
    padding: '0px 28px',
  },
  height: '80px',
  padding: '0px 100px',
  borderBottom: '1px solid #EEEEEE'
}));

const HeaderItem = styled('div')(() => ({
  '@media (max-width: 768px)': {
    fontSize: '16px',
    padding: '15px 0px'
  },
  cursor: "pointer",
  padding: '0 20px',
  fontSize: '20px',
  color: '#111111',
  fontWeight: 600
}));

const Header = ({page, innerWidth}) => {
  const navigate = useNavigate();
  const [openMobileMenu, setOpenMobileMenu] = useState(false);
  
  const handleMobileMenu = useCallback(() => {
    setOpenMobileMenu(value => !value);
  }, []);
  
  useEffect(() => {
    setOpenMobileMenu(false);
  }, [page]);

  return (
    <>
      {innerWidth > 768 ? 
        <HeaderContainer>
          <HeaderItem style={{ flexGrow: 1, textAlign: 'left' }} onClick={() => navigate("/")}>
            <img src={"/images/logo.png"} style={{height: '18px'}} />
          </HeaderItem>
          <HeaderItem style={{ ...(page.startsWith("/esg") && {color: '#00CD9B'}) }} onClick={() => navigate("/esg")}>ESG란?</HeaderItem>
          <HeaderItem style={{ ...(page.startsWith("/management") && { color: '#00CD9B' }) }} onClick={() => navigate("/management")}>탄소배출관리</HeaderItem>
          <HeaderItem style={{ ...(page.startsWith("/faq") && { color: '#00CD9B' }) }} onClick={() => navigate("/faq")}>FAQ</HeaderItem>
          <HeaderItem style={{ ...(page.startsWith("/ask") && { color: '#00CD9B' }) }} onClick={() => window.open("https://walla.my/escope.inbound", "_blank")}>문의하기</HeaderItem>
        </HeaderContainer>
        :
        <div>
          <HeaderContainer>
            <HeaderItem style={{ flexGrow: 1 }} onClick={() => navigate("/")}>
              <img src={"/images/logo.png"} style={{ height: '12px' }} />
            </HeaderItem>
            <HeaderItem style={{cursor: "pointer"}}>
              {openMobileMenu ?
                <Close  onClick={handleMobileMenu} /> :
                <MenuIcon  onClick={handleMobileMenu} />
              }
            </HeaderItem>
          </HeaderContainer>
          <Collapse in={openMobileMenu}>
            <div style={{width: '100%', backgroundColor: 'white', borderBottom: '1px solid #EEEEEE'}}>
              <div style={{height: '5px'}} />
              <HeaderItem style={{ textAlign: 'center', ...(page.startsWith("/esg") && { color: '#00CD9B' }) }} onClick={() => navigate("/esg")}>ESG란?</HeaderItem>
              <HeaderItem style={{ textAlign: 'center', ...(page.startsWith("/management") && { color: '#00CD9B' }) }} onClick={() => navigate("/management")}>탄소배출관리</HeaderItem>
              <HeaderItem style={{ textAlign: 'center', ...(page.startsWith("/faq") && { color: '#00CD9B' }) }} onClick={() => navigate("/faq")}>FAQ</HeaderItem>
              <HeaderItem style={{ textAlign: 'center', ...(page.startsWith("/ask") && { color: '#00CD9B' }) }} onClick={() => window.open("https://walla.my/escope.inbound", "_blank")}>문의하기</HeaderItem>
              <div style={{ height: '15px' }} />
            </div>
          </Collapse>
        </div>
      }
    </>
  )
}

export default Header;
