import ChevronRight from "@mui/icons-material/ChevronRight";
import { useNavigate } from "react-router-dom";
import { Title2 } from "../components/Font";


const Faq = ({innerWidth}) => {
  const navigate = useNavigate();
  
  const MobileMenu = ({content, url}) => {
    return (
      <div
        style={{ maxWidth: '100%', border: '1px solid #EEEEEE', borderRadius: '15px', padding: '16px 20px', display: 'flex', alignItems: 'center' }}
        onClick={() => navigate(url)}
      >
        <div style={{ flex: 1, fontSize: '16px' }}>{content}</div>
        <img src="/images/faqcm.png" style={{ height: '24px', width: '24px' }} />
      </div>
    )
  }
  
  return (
    <>
      {innerWidth > 768 ?
        <div style={{ display: 'flex', flex: 1, flexDirection: 'column', alignItems: 'center', padding: '100px 0' }}>
          {/* <div style={{ height: '100px' }} /> */}
          <div style={{ fontSize: '42px', fontWeight: 'bold' }}>자주 묻는 질문</div>
          <div style={{ height: '50px' }} />

          <div style={{ display: 'flex' }}>
            <div
              style={{ display: 'flex', minWidth: '640px', padding: '23px 40px', border: '1px solid #EEEEEE', borderRadius: '15px', boxSizing: 'border-box', alignItems: 'center', cursor: "pointer" }}
              onClick={() => navigate("/faq/process")}
            >
              <div style={{ flex: 1, fontSize: '24px', fontWeight: 600 }}>도입프로세스</div>
              <img src="/images/faqcm.png" style={{ height: '36px', width: '36px' }} />
            </div>
            <div style={{ width: '20px' }} />
            <div
              style={{ display: 'flex', minWidth: '640px', padding: '23px 40px', border: '1px solid #EEEEEE', borderRadius: '15px', boxSizing: 'border-box', alignItems: 'center', cursor: "pointer" }}
              onClick={() => navigate("/faq/emission")}
            >
              <div style={{ flex: 1, fontSize: '24px', fontWeight: 600 }}>배출량 산정</div>
              <img src="/images/faqcm.png" style={{ height: '36px', width: '36px' }} />
            </div>
          </div>

          <div style={{ height: '20px' }} />

          <div style={{ display: 'flex' }}>
            <div
              style={{ display: 'flex', minWidth: '640px', padding: '23px 40px', border: '1px solid #EEEEEE', borderRadius: '15px', boxSizing: 'border-box', alignItems: 'center', cursor: "pointer" }}
              onClick={() => navigate("/faq/disclosure")}
            >
              <div style={{ flex: 1, fontSize: '24px', fontWeight: 600 }}>탄소 정보 공개</div>
              <img src="/images/faqcm.png" style={{ height: '36px', width: '36px' }} />
            </div>
            <div style={{ width: '20px' }} />
            <div
              style={{ display: 'flex', minWidth: '640px', padding: '23px 40px', border: '1px solid #EEEEEE', borderRadius: '15px', boxSizing: 'border-box', alignItems: 'center', cursor: "pointer" }}
              onClick={() => navigate("/faq/roadmap")}
            >
              <div style={{ flex: 1, fontSize: '24px', fontWeight: 600 }}>탄소 중립 로드맵</div>
              <img src="/images/faqcm.png" style={{ height: '36px', width: '36px' }} />
            </div>
          </div>

          <div style={{ height: '20px' }} />

          <div style={{ display: 'flex' }}>
            <div
              style={{ display: 'flex', minWidth: '640px', padding: '23px 40px', border: '1px solid #EEEEEE', borderRadius: '15px', boxSizing: 'border-box', alignItems: 'center', cursor: "pointer" }}
              onClick={() => navigate("/faq/etc")}
            >
              <div style={{ flex: 1, fontSize: '24px', fontWeight: 600 }}>기타문의</div>
              <img src="/images/faqcm.png" style={{ height: '36px', width: '36px' }} />
            </div>
            <div style={{ width: '20px' }} />
            <div style={{ minWidth: '640px' }} />
          </div>
        </div>
        :
        <div style={{ width: '100%', padding: '50px 0', display: 'flex', flex: 1, flexDirection: 'column', alignItems: 'center' }}>
          <div style={{ width: '85%' }}>
            <Title2>자주 묻는 질문</Title2>
            <div style={{ height: '20px' }} />

            <MobileMenu content={"도입프로세스"} url={"/faq/process"} />
            <div style={{ height: '10px' }} />
            <MobileMenu content={"배출량 산정"} url={"/faq/emission"} />
            <div style={{ height: '10px' }} />
            <MobileMenu content={"탄소 정보 공개"} url={"/faq/disclosure"} />
            <div style={{ height: '10px' }} />
            <MobileMenu content={"탄소 중립 로드맵"} url={"/faq/roadmap"} />
            <div style={{ height: '10px' }} />
            <MobileMenu content={"기타문의"} url={"/faq/etc"} />
          </div>
        </div>
      }
    </>
  );
}

export default Faq;
