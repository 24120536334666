import { useState, useEffect, useRef } from "react";
import Header from './components/Header';
import { Routes, Route, useLocation } from 'react-router-dom';
import Footer from "./components/Footer";

import Index from "./pages/Index";
import Esg from "./pages/Esg";
import Management from "./pages/Management";
import Faq from "./pages/Faq";
import Process from "./pages/Process";
import Emission from "./pages/Emission";
import Disclosure from "./pages/Disclosure";
import Roadmap from "./pages/Roadmap";
import Etc from "./pages/Etc";
import NotFound from "./components/NotFound";

function App() {
  const [fillHeight, setFillHeight] = useState(true);
  const location = useLocation();
  const [innerWidth, setInnerWidth] = useState(window.innerWidth);
  const [innerHeight, setInnerHeight] = useState(window.innerHeight);
  const lastPage = useRef([location.pathname, location.pathname]);
  
  useEffect(() => {
    lastPage.current.push(location.pathname);
    lastPage.current.shift();
  }, [location.pathname]);
  
  useEffect(() => {
    if (innerHeight < 990){
      setFillHeight(false);
    }
    else{
      if(document.documentElement.scrollHeight <= (window.innerHeight || document.documentElement.clientHeight)){
        setFillHeight(true);
      }
      else{
        setFillHeight(false);
      }
    }
  }, [location.pathname, window.innerHeight, document.documentElement.clientHeight]);
  
  const enableFillHeight = () => {
    if(!fillHeight)
      setFillHeight(true);
  }
  const disableFillHeight = () => {
    if(fillHeight)
      setFillHeight(false);
  }
  
  useEffect(() => {
    const resizeListener = () => {
      setInnerWidth(window.innerWidth);
      setInnerHeight(window.innerHeight);
    };
    window.addEventListener("resize", resizeListener);
  })
  
  return (
    <div style={{ display: 'flex', flexDirection: 'column', width: '100%', ...(fillHeight && { height: '100%' }) }}>
      <Header innerWidth={innerWidth} page={location.pathname} />
      <Routes>
        <Route path="/" element={<Index innerWidth={innerWidth} />} />
        <Route path="/esg" element={<Esg innerWidth={innerWidth} />} />
        <Route path="/management" element={<Management innerWidth={innerWidth} />} />
        <Route path="/faq" element={<Faq innerWidth={innerWidth} />} />
        <Route path="/faq/process" element={<Process enableFillHeight={enableFillHeight} disableFillHeight={disableFillHeight} innerWidth={innerWidth} pHistory={lastPage} />} />
        <Route path="/faq/emission" element={<Emission enableFillHeight={enableFillHeight} disableFillHeight={disableFillHeight} innerWidth={innerWidth} pHistory={lastPage} />} />
        <Route path="/faq/disclosure" element={<Disclosure enableFillHeight={enableFillHeight} disableFillHeight={disableFillHeight} innerWidth={innerWidth} pHistory={lastPage} />} />
        <Route path="/faq/roadmap" element={<Roadmap enableFillHeight={enableFillHeight} disableFillHeight={disableFillHeight} innerWidth={innerWidth} pHistory={lastPage} />} />
        <Route path="/faq/etc" element={<Etc enableFillHeight={enableFillHeight} disableFillHeight={disableFillHeight} innerWidth={innerWidth} pHistory={lastPage} />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
      <Footer innerWidth={innerWidth} />
    </div>
  );
}

export default App;
