import { useRef, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Accordion, AccordionSummary, AccordionDetails } from "@mui/material";
import ExpandMore from "@mui/icons-material/ExpandMore";
import { Title2, Li } from "../components/Font";
import './questions.css';

const Disclosure = props => {
  const element = useRef();
  const orgScrollHeight = useRef(document.documentElement.scrollHeight);
  const [contentHeight, setContentHeight] = useState(0);
  const navigate = useNavigate();

  const handleFaq = () => {
    if ("pHistory" in props && props.pHistory.current[props.pHistory.current.length - 2] == "/faq") {
      navigate(-1);
    }
    else {
      navigate("/faq");
    }
  }

  useEffect(() => {
    const resize = new ResizeObserver(entries => {
      for (const entry of entries) {
        const { width, height } = entry.contentRect;
        setContentHeight(height);
      }
    });
    if (element.current) {
      resize.observe(element.current);
    }

    return () => {
      resize.disconnect();
    };
  }, [element]);

  useEffect(() => {
    if (orgScrollHeight.current <= contentHeight + 400) {
      props.disableFillHeight();
    }
    else {
      props.enableFillHeight();
    }
  }, [contentHeight]);
  

  return (
    <div style={{ display: 'flex', flex: 1, justifyContent: 'center', alignItems: 'center' }} >
      <div ref={element}>
        {props.innerWidth > 768 ?
          <>
            <div style={{ height: '100px' }} />
            <div style={{ color: '#AAAAAA', fontSize: '18px', fontWeight: 600, display: 'flex', alignItems: 'center' }}>
              <span style={{ cursor: 'pointer' }} onClick={() => handleFaq()}>자주 묻는 질문</span>
              <img src="/images/faqchevron.png" style={{ height: '21px', margin: '0 6px' }} /> 탄소 정보 공개
            </div>
            <div style={{ height: '20px' }} />
            <div style={{ fontSize: '42px', fontWeight: 'bold' }}>탄소 정보 공개</div>
            <div style={{ height: '40px' }} />

            <div style={{ maxWidth: '1300px' }}>
              <Accordion disableGutters elevation={0} square style={{borderBottom: '1px solid #EEEEEE'}}>
                <AccordionSummary expandIcon={<ExpandMore fontSize="large" />} >
                  <div style={{ fontSize: '24px', fontWeight: 600, padding: '20px 24px' }}>
                    <span style={{ color: '#00CD9B' }}>Q.</span> 공개되는 탄소 정보의 제 3자 검증 및 내부 검증은 어떻게 진행되나요?
                  </div>
                </AccordionSummary>
                <AccordionDetails>
                  <div style={{ fontSize: '18px', color: '#757575', margin: '0 30px' }}>
                    <ul>
                      <Li>이스코프가 인증된 온실가스 검증기관과 함께 고객의 관여 없이 제 3자 검증 프로세스를 대행해서 수행합니다. 고객은 검증 수준과 범위만 정하면 검증기관과 엔츠의 전담인력이 엔스코프를 이용해 근거자료 정합성 검증, 산정 방법론 검증 등을 수행하고 검증성명서를 발급해 드립니다.</Li>
                    </ul>
                  </div>
                </AccordionDetails>
              </Accordion>

              <Accordion disableGutters elevation={0} square style={{ borderBottom: '1px solid #EEEEEE' }}>
                <AccordionSummary expandIcon={<ExpandMore fontSize="large" />}>
                  <div style={{ fontSize: '24px', fontWeight: 600, padding: '20px 24px' }}>
                    <span style={{ color: '#00CD9B' }}>Q.</span> 탄소정보공개에는 어떤 표준들이 있고 우리 기업은 어떤 표준에 대응해야 하나요?
                  </div>
                </AccordionSummary>
                <AccordionDetails>
                  <div style={{ fontSize: '18px', color: '#757575', margin: '0 30px' }}>
                    <ul>
                      <Li>국제적으로는 CDP, ISSB(IFRS S2), CSRD, TCFD, CBAM 등, 국내에서는 KSSB, 목표관리제, 배출권거래제 등 다양한 탄소정보공개 표준이 있고 기업의 상황에 따라 다른 표준을 요구받고 있습니다.</Li>
                      <Li>보통 탄소정보공개를 하는 목적에 따라 대응해야 하는 표준이 달라지게 되며 크게 글로벌 대기업에 수출하기 위한 목적, 공시를 수행하기 위한 목적, 정뷰 규제에 대응하기 위한 목적 등으로 나뉘어집니다.</Li>
                      <Li>이스코프는 기업의 상황에 따라 현재 그리고 앞으로는 어떤 탄소정보공개를 수행해야 하는지 상담해드리고 기업이 내부 탄소전문인력 없이도 지속가능하게 탄소정보공개를 매년 수행할 수 있도록 기후공시 역량을 내재화시켜 드리는 것을 목적으로 하고 있습니다.</Li>
                    </ul>
                  </div>
                </AccordionDetails>
              </Accordion>

              <Accordion disableGutters elevation={0} square style={{ borderBottom: '1px solid #EEEEEE' }}>
                <AccordionSummary expandIcon={<ExpandMore fontSize="large" />}>
                  <div style={{ fontSize: '24px', fontWeight: 600, padding: '20px 24px' }}>
                    <span style={{ color: '#00CD9B' }}>Q.</span> 해외의 협력사가 탄소정보공개를 요청했습니다. 어떻게 대응해야 하나요?
                  </div>
                </AccordionSummary>
                <AccordionDetails>
                  <div style={{ fontSize: '18px', color: '#757575', margin: '0 30px' }}>
                    <ul>
                      <Li>이스코프는 기업 고객이 내부의 탄소 전문가 없이도 자체적으로 지속가능한 탄소정보공개를 수행할 수 있도록 돕고 있습니다.</Li>
                      <Li>유럽/북미에 위치한 글로벌 기업의 경우 공급망을 포함한 기후 공시 규제의 확대로 국내 기업에게 까지 거래 필수조건으로 탄소정보공개를 요청하는 경우가 빠르게 늘어나고 있습니다.</Li>
                    </ul>
                  </div>
                </AccordionDetails>
              </Accordion>

              <Accordion disableGutters elevation={0} square >
                <AccordionSummary className="accordionSummary" expandIcon={<ExpandMore fontSize="large" />} sx={{border: 'none'}}>
                  <div style={{ fontSize: '24px', fontWeight: 600, padding: '20px 24px' }}>
                    <span style={{ color: '#00CD9B' }}>Q.</span> 탄소정보공개에는 어떤 내용들이 포함되게 되나요?
                  </div>
                </AccordionSummary>
                <AccordionDetails>
                  <div style={{ fontSize: '18px', color: '#757575', margin: '0 30px' }}>
                    <ul>
                      <Li>기업이 수행하는 탄소정보공개 표준에 따라 포함되는 내용을 달라질 수 있습니다.</Li>
                      <Li>일반적으로는 기업 소개, 기업이 산정한 조직 경계와 설정 방법 및 근거, Scope 1, 2, 3 탄소 배출량과 산정 방법론 및 근거, 제품별 혹은 서비스별 탄소 배출량, 용수 사용량과 폐기물 배출량, 감축 목표와 전략 등과 같은 내용들이 포함되게 됩니다.</Li>
                      <Li>이스코프를 이용해 기업은 수행해야 하는 탄소정보공개에 필요한 내용들을 측정하고 관리할 수 있으며 탄소정보공개가 필요할 때 마다, 필요한 데이터를 자동으로 취합하고 보고서에 포함시킬 수 있습니다.</Li>
                    </ul>
                  </div>
                </AccordionDetails>
              </Accordion>
            </div>
            <div style={{ height: '100px' }} />
          </>
          :
          <div style={{ width: '100%', padding: '50px 0', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <div style={{ width: '85%' }}>
              <div style={{ color: '#AAAAAA', fontSize: '14px', fontWeight: 600, display: 'flex', alignItems: 'center' }}>
                <span style={{ cursor: 'pointer' }} onClick={() => handleFaq()}>자주 묻는 질문</span>
                <img src="/images/faqchevron.png" style={{ height: '16px', margin: '0 2px' }} /> 탄소 정보 공개
              </div>
              <div style={{ height: '10px' }} />
              <Title2>탄소 정보 공개</Title2>
              <div style={{ height: '30px' }} />

              <div>
                <Accordion disableGutters elevation={0} square style={{ borderBottom: '1px solid #EEEEEE' }}>
                  <AccordionSummary expandIcon={<ExpandMore />} sx={{ padding: '0 10px' }} >
                    <div style={{ fontSize: '14px', fontWeight: 600, padding: '8px 10px', display: 'flex' }}>
                      <div style={{ color: '#00CD9B', marginRight: '6px' }}>Q.</div>
                      <div>공개되는 탄소 정보의 제 3자 검증 및 내부 검증은 어떻게 진행되나요?</div>
                    </div>
                  </AccordionSummary>
                  <AccordionDetails sx={{ padding: '0' }}>
                    <div style={{ fontSize: '18px', color: '#757575', margin: '-20px 40px 0 14px' }}>
                      <ul>
                        <Li>이스코프가 인증된 온실가스 검증기관과 함께 고객의 관여 없이 제 3자 검증 프로세스를 대행해서 수행합니다. 고객은 검증 수준과 범위만 정하면 검증기관과 엔츠의 전담인력이 엔스코프를 이용해 근거자료 정합성 검증, 산정 방법론 검증 등을 수행하고 검증성명서를 발급해 드립니다.</Li>
                      </ul>
                    </div>
                  </AccordionDetails>
                </Accordion>

                <Accordion disableGutters elevation={0} square style={{ borderBottom: '1px solid #EEEEEE' }}>
                  <AccordionSummary expandIcon={<ExpandMore />} sx={{ padding: '0 10px' }} >
                    <div style={{ fontSize: '14px', fontWeight: 600, padding: '8px 10px', display: 'flex' }}>
                      <div style={{ color: '#00CD9B', marginRight: '6px' }}>Q.</div>
                      <div>탄소정보공개에는 어떤 표준들이 있고 우리 기업은 어떤 표준에 대응해야 하나요?</div>
                    </div>
                  </AccordionSummary>
                  <AccordionDetails sx={{ padding: '0' }}>
                    <div style={{ fontSize: '18px', color: '#757575', margin: '-20px 40px 0 14px' }}>
                      <ul>
                        <Li>국제적으로는 CDP, ISSB(IFRS S2), CSRD, TCFD, CBAM 등, 국내에서는 KSSB, 목표관리제, 배출권거래제 등 다양한 탄소정보공개 표준이 있고 기업의 상황에 따라 다른 표준을 요구받고 있습니다.</Li>
                        <div style={{ height: '10px' }} />
                        <Li>보통 탄소정보공개를 하는 목적에 따라 대응해야 하는 표준이 달라지게 되며 크게 글로벌 대기업에 수출하기 위한 목적, 공시를 수행하기 위한 목적, 정뷰 규제에 대응하기 위한 목적 등으로 나뉘어집니다.</Li>
                        <div style={{ height: '10px' }} />
                        <Li>이스코프는 기업의 상황에 따라 현재 그리고 앞으로는 어떤 탄소정보공개를 수행해야 하는지 상담해드리고 기업이 내부 탄소전문인력 없이도 지속가능하게 탄소정보공개를 매년 수행할 수 있도록 기후공시 역량을 내재화시켜 드리는 것을 목적으로 하고 있습니다.</Li>
                      </ul>
                    </div>
                  </AccordionDetails>
                </Accordion>

                <Accordion disableGutters elevation={0} square style={{ borderBottom: '1px solid #EEEEEE' }}>
                  <AccordionSummary expandIcon={<ExpandMore />} sx={{ padding: '0 10px' }} >
                    <div style={{ fontSize: '14px', fontWeight: 600, padding: '8px 10px', display: 'flex' }}>
                      <div style={{ color: '#00CD9B', marginRight: '6px' }}>Q.</div>
                      <div>해외의 협력사가 탄소정보공개를 요청했습니다. 어떻게 대응해야 하나요?</div>
                    </div>
                  </AccordionSummary>
                  <AccordionDetails sx={{ padding: '0' }}>
                    <div style={{ fontSize: '18px', color: '#757575', margin: '-20px 40px 0 14px' }}>
                      <ul>
                        <Li>이스코프는 기업 고객이 내부의 탄소 전문가 없이도 자체적으로 지속가능한 탄소정보공개를 수행할 수 있도록 돕고 있습니다.</Li>
                        <div style={{ height: '10px' }} />
                        <Li>유럽/북미에 위치한 글로벌 기업의 경우 공급망을 포함한 기후 공시 규제의 확대로 국내 기업에게 까지 거래 필수조건으로 탄소정보공개를 요청하는 경우가 빠르게 늘어나고 있습니다.</Li>
                      </ul>
                    </div>
                  </AccordionDetails>
                </Accordion>

                <Accordion disableGutters elevation={0} square >
                  <AccordionSummary expandIcon={<ExpandMore />} sx={{ padding: '0 10px' }} >
                    <div style={{ fontSize: '14px', fontWeight: 600, padding: '8px 10px', display: 'flex' }}>
                      <div style={{ color: '#00CD9B', marginRight: '6px' }}>Q.</div>
                      <div>탄소정보공개에는 어떤 내용들이 포함되게 되나요?</div>
                    </div>
                  </AccordionSummary>
                  <AccordionDetails sx={{ padding: '0' }}>
                    <div style={{ fontSize: '18px', color: '#757575', margin: '-20px 40px 0 14px' }}>
                      <ul>
                        <Li>기업이 수행하는 탄소정보공개 표준에 따라 포함되는 내용을 달라질 수 있습니다.</Li>
                        <div style={{ height: '10px' }} />
                        <Li>일반적으로는 기업 소개, 기업이 산정한 조직 경계와 설정 방법 및 근거, Scope 1, 2, 3 탄소 배출량과 산정 방법론 및 근거, 제품별 혹은 서비스별 탄소 배출량, 용수 사용량과 폐기물 배출량, 감축 목표와 전략 등과 같은 내용들이 포함되게 됩니다.</Li>
                        <div style={{ height: '10px' }} />
                        <Li>이스코프를 이용해 기업은 수행해야 하는 탄소정보공개에 필요한 내용들을 측정하고 관리할 수 있으며 탄소정보공개가 필요할 때 마다, 필요한 데이터를 자동으로 취합하고 보고서에 포함시킬 수 있습니다.</Li>
                      </ul>
                    </div>
                  </AccordionDetails>
                </Accordion>
              </div>
            </div>
          </div>
        }
      </div>
    </div>
  )
};

export default Disclosure;
