const Footer = ({innerWidth}) => {
  return (
    <>
    {innerWidth > 768 ?
      <div style={{ width: '100%', padding: '100px 0', backgroundColor: '#000000', color: '#FFFFFF', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
        <div style={{ width: '100%', maxWidth: '1300px', padding: '0 100px'}}>
          
          <div style={{display: 'flex', alignItems: 'center'}}>
            <div style={{ flex: 1, fontWeight: 'bold' }}>
              <img src="/images/logo_white.png" style={{ height: '18.88px' }} />
            </div>
            <div style={{fontWeight: 'bold'}}>이용약관</div>
            <div style={{margin: '0px 30px'}}>|</div>
            <div style={{ fontWeight: 'bold' }}>개인정보처리방침</div>
          </div>
          
          <div style={{ height: '20px' }} />

          <div style={{ display: 'flex', flex: 1 }}>
            대표 : 홍성욱
            <div style={{ width: '30px' }} />
            주소 : 서울시 강남구 봉은사로 121
            <div style={{ width: '30px' }} />
            이메일 : esgmatekr@gmail.com
            <div style={{ width: '30px' }} />
            사업자등록번호 : 286-86-01500
            <div style={{ width: '30px' }} />
            개인정보처리 관리 : 홍성욱
          </div>
          
          <div style={{ height: '20px' }} />
          
          <div>Copyright © 2023 E-scope+ All rights reserved.</div>
          
        </div>
      </div>
      :
        <div style={{ width: '100%', padding: '50px 28px', backgroundColor: '#000000', color: '#FFFFFF', boxSizing: 'border-box' }}>
          <div style={{height: '27px', display: 'flex', alignItems: 'center'}}>
            <img src="/images/logo_white.png" style={{height: '12.32px'}} />
          </div>
          <div style={{height: '20px'}} />
          <div style={{ fontSize: '12px', fontWeight: 600 }}>이용약관<span style={{ margin: '0px 10px' }}>|</span>개인정보처리방침</div>
          
          <div style={{ height: '30px' }} />
            
          <div style={{fontSize: '12px', lineHeight: 2}}>
            <div>대표 : 홍성욱</div>
            <div>주소 : 서울시 강남구 봉은사로 121</div>
            <div>이메일 : esgmatekr@gmail.com</div>
            <div>사업자등록번호 : 286 - 86-01500</div>
            <div>개인정보처리 관리: 홍성욱</div>
          </div>
          
          <div style={{ height: '30px' }} />
          
          <div style={{ fontSize: '12px' }}>Copyright © 2023 E-scope+ All rights reserved.</div>
        </div>
    }
    </>
  )
};

export default Footer;
