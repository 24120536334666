import { useRef, useState, useEffect } from "react";
import { Slide } from "@mui/material";
import { Title2, BodyText, Li } from "../components/Font";

const Esg = ({innerWidth}) => {
  const slideRef = useRef();
  const [slide, setSlide] = useState(false);
  
  useEffect(() => {
    setSlide(true);
  }, []);
  
  return (
    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%' }}>
      
      {innerWidth > 768 ?
        <div ref={slideRef} style={{ width: '100%', padding: '100px 0', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <Slide direction="up" in={slide} container={slideRef.current} timeout={800}>
            <div>
              <div style={{ fontSize: '42px', fontWeight: 'bold' }}>ESG란?</div>
              <div style={{ height: '20px' }} />
              <div style={{ fontSize: '18px', color: '#757575', marginBottom: '5px' }}>ESG는 환경(Environmental), 사회(Social), 지배구조(Govermence)의 영문 첫 글자를 조합한 단어로,</div>
              <div style={{ fontSize: '18px', color: '#757575' }}>기업 경영에서 지속가능성을 달성하기기 위한 3가지 핵심 요소를 의미합니다.</div>
              <div style={{ height: '40px' }} />
              <div style={{ display: 'flex' }}>
                <div>
                  <div style={{
                    backgroundColor: '#00CD9B', color: '#FFFFFF', fontSize: '24px', fontWeight: 600, borderRadius: '10px 10px 0 0',
                    width: '406px', height: '76px', display: 'flex', justifyContent: 'center', alignItems: 'center'
                  }}>
                    Environmental
                  </div>
                  <div style={{
                    border: '1px solid #EEEEEE', borderRadius: '0 0 10px 10px', width: '406px', height: '121px',
                    display: 'flex', justifyContent: 'center', alignItems: 'center', fontSize: '18px', backgroundColor: '#FFFFFF'
                  }}>
                    <ul>
                      <li style={{ marginBottom: '5px' }}>기후변화</li>
                      <li style={{ marginBottom: '5px' }}>탄소배출</li>
                      <li>생태계 및 생물 다양성</li>
                    </ul>
                  </div>
                </div>
                <div style={{ width: '40px' }} />
                <div>
                  <div>
                    <div style={{
                      backgroundColor: '#00CD9B', color: '#FFFFFF', fontSize: '24px', fontWeight: 600, borderRadius: '10px 10px 0 0',
                      width: '406px', height: '76px', display: 'flex', justifyContent: 'center', alignItems: 'center'
                    }}>
                      Social
                    </div>
                    <div style={{
                      border: '1px solid #EEEEEE', borderRadius: '0 0 10px 10px', width: '406px', height: '121px',
                      display: 'flex', justifyContent: 'center', alignItems: 'center', fontSize: '18px', backgroundColor: '#FFFFFF'
                    }}>
                      <ul>
                        <li style={{ marginBottom: '5px' }}>데이터 보호 및 프라이버시</li>
                        <li style={{ marginBottom: '5px' }}>인권, 성별 평등 및 다양성</li>
                        <li>지역사회 관계</li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div style={{ width: '40px' }} />
                <div>
                  <div>
                    <div style={{
                      backgroundColor: '#00CD9B', color: '#FFFFFF', fontSize: '24px', fontWeight: 600, borderRadius: '10px 10px 0 0',
                      width: '406px', height: '76px', display: 'flex', justifyContent: 'center', alignItems: 'center'
                    }}>
                      Governence
                    </div>
                    <div style={{
                      border: '1px solid #EEEEEE', borderRadius: '0 0 10px 10px', width: '406px', height: '121px',
                      display: 'flex', justifyContent: 'center', alignItems: 'center', fontSize: '18px', backgroundColor: '#FFFFFF'
                    }}>
                      <ul>
                        <li style={{ marginBottom: '5px' }}>이사회 및 감사위원회 구성</li>
                        <li style={{ marginBottom: '5px' }}>뇌물 및 반부패</li>
                        <li>기업윤리</li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Slide>
        </div>
        :
        <div ref={slideRef} style={{ width: '100%', padding: '50px 0', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <div style={{width: '85%'}}>
            <Slide direction="up" in={slide} container={slideRef.current} timeout={800}>
              <div>
                <Title2>ESG란?</Title2>
                <div style={{ height: '20px' }} />
                <BodyText>ESG는 환경(Environmental),</BodyText>
                <BodyText>사회(Social), 지배구조(Govermence)의</BodyText>
                <BodyText>영문 첫 글자를 조합한 단어로, 기업 경영에서</BodyText>
                <BodyText>지속가능성을 달성하기기 위한 3가지 핵심</BodyText>
                <BodyText>요소를 의미합니다.</BodyText>
                <div style={{ height: '30px' }} />
                <div>
                  <div style={{
                    backgroundColor: '#00CD9B', color: '#FFFFFF', fontSize: '18px', fontWeight: 600, borderRadius: '10px 10px 0 0',
                    width: '100%', maxWidth: '425px', height: '53px', display: 'flex', justifyContent: 'center', alignItems: 'center'
                  }}>
                    Environmental
                  </div>
                  <div style={{
                    border: '1px solid #EEEEEE', borderRadius: '0 0 10px 10px', width: '100%', maxWidth: '425px', height: '91px',
                    display: 'flex', justifyContent: 'center', alignItems: 'center', fontSize: '14px'
                  }}>
                    <ul>
                      <Li>기후변화</Li>
                      <Li>탄소배출</Li>
                      <Li>생태계 및 생물 다양성</Li>
                    </ul>
                  </div>
                </div>
                <div style={{ height: '20px' }} />
                <div>
                  <div>
                    <div style={{
                      backgroundColor: '#00CD9B', color: '#FFFFFF', fontSize: '18px', fontWeight: 600, borderRadius: '10px 10px 0 0',
                      width: '100%', maxWidth: '425px', height: '53px', display: 'flex', justifyContent: 'center', alignItems: 'center'
                    }}>
                      Social
                    </div>
                    <div style={{
                      border: '1px solid #EEEEEE', borderRadius: '0 0 10px 10px', width: '100%', maxWidth: '425px', height: '91px',
                      display: 'flex', justifyContent: 'center', alignItems: 'center', fontSize: '14px'
                    }}>
                      <ul>
                        <Li>데이터 보호 및 프라이버시</Li>
                        <Li>인권, 성별 평등 및 다양성</Li>
                        <Li>지역사회 관계</Li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div style={{ height: '20px' }} />
                <div>
                  <div>
                    <div style={{
                      backgroundColor: '#00CD9B', color: '#FFFFFF', fontSize: '18px', fontWeight: 600, borderRadius: '10px 10px 0 0',
                      width: '100%', maxWidth: '425px', height: '53px', display: 'flex', justifyContent: 'center', alignItems: 'center'
                    }}>
                      Governence
                    </div>
                    <div style={{
                      border: '1px solid #EEEEEE', borderRadius: '0 0 10px 10px', width: '100%', maxWidth: '425px', height: '91px',
                      display: 'flex', justifyContent: 'center', alignItems: 'center', fontSize: '14px'
                    }}>
                      <ul>
                        <Li>이사회 및 감사위원회 구성</Li>
                        <Li>뇌물 및 반부패</Li>
                        <Li>기업윤리</Li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </Slide>
          </div>
        </div>
      }
      
      
      {innerWidth > 768 ?
        <div style={{ width: '100%', padding: '100px 0', display: 'flex', justifyContent: 'center', alignItems: 'center', backgroundColor: '#F5F9F8', zIndex: 1 }}>
          <div style={{ display: 'flex' }}>
            <div style={{ width: '643px', height: '460px', backgroundImage: 'url(/images/esg1.avif)', backgroundSize: 'auto 100%', backgroundRepeat: 'no-repeat', borderRadius: '15px' }} />
            <div style={{ width: '60px' }} />
            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
              <Title2>투자자와 소비자도 기업평가의</Title2>
              <Title2>기준을 재무적 가치가 아닌,</Title2>
              <Title2 style={{ color: '#00CD9B' }}>비재무적 가치로 중시하고 있습니다.</Title2>
              <div style={{ height: '20px' }} />
              <BodyText>과거에는 ‘얼마를 투자하여, 얼마를 벌었는가?’ 등 재무적 정량지표가 기준이었다면,<br/>
              현재는 기후변화 등 비재무적 지표가 실질적 가치평가에 더 중요한 기준이 되고 있습니다.</BodyText>
            </div>
          </div>
        </div>
        :
        <div style={{ width: '100%', padding: '50px 0', display: 'flex', flexDirection: 'column', alignItems: 'center', backgroundColor: '#F5F9F8', zIndex: 1 }}>
          <div style={{ width: '85%' }}>
            <img src="/images/esg1.avif" style={{width: '100%', borderRadius: '15px'}} />
            <div style={{ height: '40px' }} />
            <Title2>투자자와 소비자도 기업평가의</Title2>
            <Title2>기준을 재무적 가치가 아닌,</Title2>
            <Title2 style={{ color: '#00CD9B' }}>비재무적 가치로<br/>중시하고 있습니다.</Title2>
            <div style={{ height: '20px' }} />
            <BodyText>과거에는 ‘얼마를 투자하여,</BodyText>
            <BodyText>얼마를 벌었는가?’ 등 재무적 정량지표가</BodyText>
            <BodyText>기준이었다면, 현재는 기후변화 등</BodyText>
            <BodyText>비재무적 지표가 실질적 가치평가에</BodyText>
            <BodyText>더 중요한 기준이 되고 있습니다.</BodyText>
          </div>
        </div>
      }
      
      
      {innerWidth > 768 ?
        <div style={{ width: '100%', padding: '100px 0', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <Title2 style={{ textAlign: 'center' }}>ESG의 필요성</Title2>
          <div style={{ height: '40px' }} />

          <div style={{
            width: '1300px', height: '420px', background: 'linear-gradient(90deg, rgba(0,0,0,0.7) 40%,rgba(0,0,0,0) 50% ), url(/images/esg6.avif)',
            backgroundSize: '130%', backgroundPosition: '0px 48%', display: 'flex', alignItems: 'center', borderRadius: '15px'
          }}>
            <div style={{ width: '120px' }} />
            <div>
              <div style={{ fontSize: '24px', fontWeight: 700, color: '#00CD9B' }}>제품이 변해도 나쁜 기업은 소비하지 않습니다</div>
              <div style={{ height: '10px' }} />
              <div style={{ fontSize: '34px', fontWeight: 600, color: '#FFFFFF' }}>소비자의 인식 변화</div>
              <div style={{ height: '30px' }} />
              <div style={{ fontSize: '18px', color: '#FFFFFF', marginBottom: '5px' }}>오늘날 소비자는 사회적 책임을 소홀히 한 회사의 저렴한 제품보다는</div>
              <div style={{ fontSize: '18px', color: '#FFFFFF' }}>가격이 높더라도 사회적 책임을 적극적으로 수행하는 회사의 제품을 선호합니다.</div>
            </div>
          </div>

          <div style={{ height: '30px' }} />

          <div style={{
            width: '1300px', height: '420px', background: 'linear-gradient(90deg, rgba(0,0,0,0),rgba(0,0,0,0.9) ), url(/images/esg3.jpg)',
            backgroundSize: '100% auto', backgroundPosition: '0px 40%', display: 'flex', flexDirection: 'row-reverse', alignItems: 'center', borderRadius: '15px'
          }}>
            <div style={{ width: '120px' }} />
            <div>
              <div style={{ fontSize: '24px', fontWeight: 700, color: '#00CD9B' }}>날로 심해지는 ESG 관련 규제 대응은 기업 생존의 문제입니다</div>
              <div style={{ height: '10px' }} />
              <div style={{ fontSize: '34px', fontWeight: 600, color: '#FFFFFF' }}>ESG 규제 대응</div>
              <div style={{ height: '30px' }} />
              <div style={{ fontSize: '18px', color: '#FFFFFF', marginBottom: '5px' }}>탄소국경세 도입 이슈 등 나날이 심해지는 ESG 관련 규제로 인해</div>
              <div style={{ fontSize: '18px', color: '#FFFFFF' }}>수출 중심 국가인 우리 기업은 경쟁력에 심각한 영향을 받을 수 있습니다.</div>
            </div>
          </div>

          <div style={{ height: '30px' }} />

          <div style={{
            width: '1300px', height: '420px', background: 'linear-gradient(90deg, rgba(0,0,0,0.9),rgba(0,0,0,0) ), url(/images/esg4.avif)',
            backgroundSize: '100% auto', backgroundPosition: '0px 30%', display: 'flex', alignItems: 'center', borderRadius: '15px'
          }}>
            <div style={{ width: '120px' }} />
            <div>
              <div style={{ fontSize: '24px', fontWeight: 700, color: '#00CD9B' }}>ESG 경영이 구축되지 않으면 자금이 투입되지 않습니다</div>
              <div style={{ height: '10px' }} />
              <div style={{ fontSize: '34px', fontWeight: 600, color: '#FFFFFF' }}>ESG 관련 책임 투자자</div>
              <div style={{ height: '30px' }} />
              <div style={{ fontSize: '18px', color: '#FFFFFF', marginBottom: '5px' }}>최근 기업의 ESG 경영을 고려하여 투자하는 글로벌 책임투자 규모가 커지고 있으며</div>
              <div style={{ fontSize: '18px', color: '#FFFFFF' }}>부족한 기업일 경우, 기관 투자자가 직접 의결권을 행사하기도 합니다.</div>
            </div>
          </div>

          <div style={{ height: '30px' }} />

          <div style={{
            width: '1300px', height: '420px', background: 'linear-gradient(90deg, rgba(0,0,0,0),rgba(0,0,0,0.8) ), url(/images/esg5.avif)',
            backgroundSize: '100% auto', backgroundPosition: '0px 50%', display: 'flex', flexDirection: 'row-reverse', alignItems: 'center', borderRadius: '15px'
          }}>
            <div style={{ width: '120px' }} />
            <div>
              <div style={{ fontSize: '24px', fontWeight: 700, color: '#00CD9B' }}>기업의 가치 평가, ESG에 달려있습니다</div>
              <div style={{ height: '10px' }} />
              <div style={{ fontSize: '34px', fontWeight: 600, color: '#FFFFFF' }}>ESG의 성과</div>
              <div style={{ height: '30px' }} />
              <div style={{ fontSize: '18px', color: '#FFFFFF', marginBottom: '5px' }}>주요 글로벌 신용 평가사들이 기업 신용도 평가에 따라 ESG 경영을 반영하고 있습니다.</div>
              <div style={{ fontSize: '18px', color: '#FFFFFF', marginBottom: '5px' }}>ESG 경영 구축을 소홀히 할 경우, 가치 평가에 부정적인 영향을 초래하여</div>
              <div style={{ fontSize: '18px', color: '#FFFFFF' }}>금융비용이 증가할 수 있습니다.</div>
            </div>
          </div>
        </div>
        :
        <div style={{ width: '100%', padding: '50px 0', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <div style={{width: '85%'}}>
            <Title2 style={{ textAlign: 'center' }}>ESG의 필요성</Title2>
            <div style={{ height: '30px' }} />
            
            <img src="/images/esg6m.png" style={{width: '100%'}}/>
            <div style={{ height: '20px' }} />
            <img src="/images/esg7m.png" style={{ width: '100%' }} />
            <div style={{ height: '20px' }} />
            <img src="/images/esg8m.png" style={{ width: '100%' }} />
            <div style={{ height: '20px' }} />
            <img src="/images/esg9m.png" style={{ width: '100%' }} />
          </div>
        </div>
      }
      
      
    </div>
  )
};

export default Esg;
